<div
  class="dropdown-container"
  [ngClass]="{
    'tutorial-input': isTutorial
  }"
  clickOutside
  [attr.id]="id"
  [class.dropdown-container--disabled]="disabled"
  (clickedOutsideEvent)="handleClickOutsideEvent()"
>
  <div class="d-flex w-100" (click)="handleContainerClick($event)">
    <ng-container *ngIf="editable && !filterable">
      <input
        #editableInput
        class="dropdown-editable-control"
        [style.padding-left]="dropdownIcon ? '4rem' : ''"
        type="text"
        [ngModel]="value"
        [mask]="mask"
        [suffix]="suffix"
        [disabled]="disabled"
        [placeholder]="placeholder"
        (input)="handleEditableInputEvent($event)"
      />
    </ng-container>
    <ng-container *ngIf="!editable && !filterable">
      <span
        #focusableInput
        role="combobox"
        [ngClass]="
          textCentered
            ? 'dropdown-label dropdown-label--center'
            : 'dropdown-label'
        "
        [style.padding-left]="dropdownIcon ? '4rem' : ''"
      >
        <span *ngIf="!value ? !value : multiple" class="color-hannotationgray">
          {{ placeholder }}
        </span>

        {{ label || "&nbsp;" }}
      </span>
    </ng-container>

    <ng-container *ngIf="filterable">
      <input
        #filterableInput
        class="dropdown-editable-control"
        [style.padding-left]="dropdownIcon ? '4rem' : ''"
        type="text"
        [ngModel]="label"
        [placeholder]="placeholder"
        [disabled]="disabled"
        (input)="handleFilterDropdownOptions($event)"
        (keydown.backspace)="handleBackspaceEvent()"
      />
    </ng-container>

    <button
      type="button"
      class="dropdown-trigger"
      [disabled]="disabled"
      (click)="editable ? handleDropdownTriggerClick($event) : null"
    >
      <span
        class="dropdown-trigger-icon"
        [class.dropdown-trigger--active]="isActive"
      >
        <icon name="chevron-right" />
      </span>
    </button>
  </div>

  <ng-container *ngIf="isActive">
    <ul
      role="listbox"
      [ngClass]="
        textCentered
          ? 'dropdown-option-list dropdown-option-list--center'
          : 'dropdown-option-list'
      "
      [@expandCollapse]="isActive"
    >
      <ng-container *ngFor="let option of filteredOptions; let i = index">
        <ng-container *ngIf="!multiple; else multipleDropdown">
          <ng-container *ngIf="tooltipAvailable; else noTooltip">
            <dropdown-item
              [id]="id + '_' + i"
              [tooltip]="option.label"
              [option]="option"
              [selected]="isSelected(option)"
              (clickEvent)="handleOptionSelectEvent($event, option)"
            />
          </ng-container>
          <ng-template #noTooltip>
            <dropdown-item
              [id]="id + '_' + i"
              [option]="option"
              [selected]="isSelected(option)"
              (clickEvent)="handleOptionSelectEvent($event, option)"
            />
          </ng-template>
        </ng-container>

        <ng-template #multipleDropdown>
          <dropdown-item
            [id]="id + '_' + i"
            [option]="option"
            [selected]="isOptionSelected(option)"
            (clickEvent)="handleMultipleOptionSelect(option)"
          />
        </ng-template>
      </ng-container>

      <li *ngIf="isEmpty" class="dropdown-empty-message">
        <ng-container *ngIf="!emptyTemplate; else empty">
          {{ emptyMessage || ("GLOBAL.NO_DATA_TO_SHOW" | translate) }}
        </ng-container>

        <ng-template #empty *ngTemplateOutlet="emptyTemplate" />
      </li>
    </ul>
  </ng-container>
</div>
