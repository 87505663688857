import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { Config } from "src/app/shared/interfaces/student-pass-criteria-api";
import {
  statsCriteriaEditLoad,
  statsCriteriaEditLoadFailure,
  statsCriteriaEditLoadSuccess,
} from "../../actions/stats/stats-criteria-edit.actions";

export interface StatsCriteriaEditState {
  criteria: Config | null;
  error: HttpErrorResponse | null;
}

const initialState: StatsCriteriaEditState = {
  criteria: null,
  error: null,
};

export const createStatsCriteriaEditReducer = createReducer(
  initialState,
  on(
    statsCriteriaEditLoad,
    (state): StatsCriteriaEditState => ({
      ...state,
      criteria: null,
      error: null,
    }),
  ),
  on(
    statsCriteriaEditLoadSuccess,
    (state, action): StatsCriteriaEditState => ({
      ...state,
      criteria: action.criteria,
      error: null,
    }),
  ),
  on(statsCriteriaEditLoadFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
