import { PassCriteriaType } from "src/app/shared/enums/pass-criteria-type";
import { StudentRatingSystemGrade } from "../interfaces/student-rating-system-grade";

export class StudentRatingSystemFilterFormData {
  constructor(
    readonly grades: Array<StudentRatingSystemGrade>,
    readonly half_grades_visible: boolean,
    readonly pass_criteria: PassCriteriaType,
  ) {}
}
