<modal-overlay>
  <modal-box>
    <div class="info-modal">
      <h3 class="text-center">
        {{ "QUIZ.QUIZ_REQUIREMENTS_TITLE" | translate }}
      </h3>
      <form [formGroup]="form">
        <div class="mt-4">
          <span class="info-modal__title">
            {{ "QUIZ.QUIZ_TIME_LIMIT" | translate }}
          </span>
          <div class="d-flex justify-content-center align-items-center mt-5">
            <input
              class="info-modal__input info-modal__input--time"
              type="text"
              formControlName="time_limit"
            />
          </div>
        </div>

        <div class="mt-5">
          <span class="info-modal__title">
            {{ "QUIZ.QUIZ_MAX_ATTEMPTS" | translate }}
          </span>
          <div class="d-flex justify-content-center align-items-center mt-5">
            <input
              class="info-modal__input"
              [type]="data.maxAttempts ? 'number' : 'text'"
              formControlName="max_attempts"
            />
          </div>
        </div>
      </form>

      <div class="info-modal__buttons mt-5">
        <button class="btn-blue-light-600 w-50" (click)="closeModal()">
          {{ "GLOBAL.CANCEL_BUTTON" | translate }}
        </button>
        <button class="btn-primary-900 w-50" (click)="startQuiz()">
          {{ "QUIZ.QUIZ_START" | translate }}
        </button>
      </div>
    </div>
  </modal-box>
</modal-overlay>
