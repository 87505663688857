import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { isEmpty, isNil } from "lodash-es";
import { InputType } from "../../types/input-type";
import { Nillable } from "../../types/nillable";
import { uniqueComponentId } from "../../utils/unique-component-id";
import { SelectItem } from "./models/select-item";

@Component({
  selector: "dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
  animations: [
    trigger("expandCollapse", [
      state("void", style({ opacity: "0" })),
      transition(":enter, :leave", [animate("0.2s ease-in-out")]),
    ]),
  ],
})
export class DropdownComponent<Value>
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() id: Nillable<string>;
  @Input() placeholder: string = "";
  @Input() emptyMessage: string = "";
  @Input() disabled: boolean = false;
  @Input() mask: Nillable<string>;
  @Input() suffix: Nillable<string>;
  @Input() tooltipAvailable: boolean = false;

  @Input() options: Nillable<SelectItem<Value>>[];
  @Input() editable: Nillable<boolean>;
  @Input() filterable: Nillable<boolean>;
  @Input() dropdownIcon: Nillable<boolean> = false;

  @Input() emptyTemplate: Nillable<TemplateRef<unknown>>;
  @Input() multiple: boolean = false;
  @Input() isTutorial: boolean = false;
  @Input() textCentered: boolean = false;

  @ViewChild("editableInput")
  editableInputRef: Nillable<ElementRef>;
  @ViewChild("focusableInput")
  focusableInputRef: Nillable<ElementRef>;
  @ViewChild("filterableInput")
  filterableInputRef: Nillable<ElementRef>;

  selectedOptions: SelectItem<Value>[] = [];
  filteredOptions: Nillable<SelectItem<Value>>[];
  @Output() selectedOptionsEmitter = new EventEmitter<SelectItem<Value>>();

  onChange = (value: Nillable<Value>): void => {};
  onTouched = () => {};

  isActive = false;
  type: InputType = "text";
  value: Nillable<Value>;

  get isEmpty(): boolean {
    return isNil(this.options) || isEmpty(this.options);
  }

  get label(): Nillable<string> {
    const option = this.findSelectedOption();
    return option?.value ? option?.label ?? null : null;
  }

  ngOnInit(): void {
    this.id = this.id || uniqueComponentId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.filteredOptions = this.options;
    }
  }

  isSelected(option: SelectItem<Value>): boolean {
    return this.value === option.value;
  }

  isOptionSelected(option: SelectItem<Value>): boolean {
    return this.selectedOptions.includes(option);
  }

  handleContainerClick(event: MouseEvent): void {
    if (this.editable) {
      return;
    }

    this.toggleActiveState();
  }

  handleEditableInputEvent(event: Event): void {
    const value = (event.target as HTMLInputElement).value as Value;

    this.updateModel(value, event);

    if (!this.isActive) {
      this.isActive = true;
    }
  }

  handleDropdownTriggerClick(event: MouseEvent): void {
    event.stopImmediatePropagation();

    this.toggleActiveState();
  }

  handleOptionSelectEvent(event: Event, option: SelectItem<Value>): void {
    event.stopImmediatePropagation();
    this.filteredOptions = this.options;
    this.updateModel(option.value, event);
    this.isActive = false;
    this.selectedOptionsEmitter.emit(option);
  }

  handleBackspaceEvent(): void {
    this.filteredOptions = this.options;
    this.value = null;
    this.selectedOptionsEmitter.emit();
  }

  handleFilterDropdownOptions(event: Event): void {
    const value = (event.target as HTMLInputElement).value as string;

    if (value.trim() === "") {
      this.value = null;
    } else {
      this.filteredOptions = this.options.filter((option) =>
        option.label.toLowerCase().includes(value.toLowerCase()),
      );
    }
  }

  handleMultipleOptionSelect(option: SelectItem<Value>): void {
    this.filterableInputRef.nativeElement.value = "";
    this.selectedOptionsEmitter.emit(option);
  }

  handleClickOutsideEvent(): void {
    this.isActive = false;
  }

  registerOnChange(fn: (value: Nillable<Value>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: Value): void {
    this.value = value;
    this.updateEditableLabel();
  }

  private updateModel(value: Value, event?: Event): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
    this.writeValue(value);
  }

  private updateEditableLabel(): void {
    if (isNil(this.editableInputRef)) {
      return;
    }

    this.editableInputRef.nativeElement.value = this.value;
  }

  private toggleActiveState(): void {
    this.isActive = !this.isActive;
  }

  private findSelectedOption(): SelectItem<Value> {
    return this.options?.find((option) => this.isSelected(option));
  }
}
