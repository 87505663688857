<div class="course-topic">
  <course-topic-header [currentTopic]="currentTopic" />

  <ng-container *ngIf="isTeacher">
    <div *ngFor="let tutorial of tutorialSteps">
      <tutorial-text-box
        *ngIf="(tutorial$ | async) === tutorial.step"
        [customStyle]="{
          'margin-left': '-415px'
        }"
        [title]="'TUTORIAL_TEACHER_DATA.' + tutorial.title | translate"
        [message]="'TUTORIAL_TEACHER_DATA.' + tutorial.message | translate"
        [step]="tutorial.step"
      />
    </div>
  </ng-container>
  <div
    [ngClass]="{
      'tutorial-border': isCurrentTutorialStep(tutorial$ | async) && isTeacher
    }"
  >
    <course-topic-exercises
      [section]="null"
      [teacher]="isTeacher"
      [courseId]="courseId"
      [groupId]="activeGroup"
      [currentTopic]="currentTopic"
      [exercises]="exercises"
      [studentsList]="studentsList"
      [emitters]="{
        startExercise: startExercise,
        stopExercise: stopExercise,
        getHelp: getHelp,
        getVideoHelp: getVideoHelp,
        answer: answer
      }"
    />
  </div>

  <ng-container *ngIf="!isIndividualListEmpty">
    <course-topic-exercises
      headerLabel="COURSES.COURSE.EXERCISES_FOR_INDIVIDUAL_WORK"
      class="course-topic-exercises-individual"
      [subtitleLabel]="individualSubtitleLabel"
      [section]="ExerciseSection.INDIVIDUAL"
      [teacher]="isTeacher"
      [courseId]="courseId"
      [groupId]="activeGroup"
      [currentTopic]="currentTopic"
      [exercises]="individualExercises"
      [emitters]="{
        startExercise: startExercise,
        stopExercise: stopExercise,
        getHelp: getHelp,
        getVideoHelp: getVideoHelp,
        answer: answer
      }"
    />
  </ng-container>
</div>
