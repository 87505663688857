import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { StatsService } from "../../services/statistics/stats.service";
import { Observable } from "rxjs";
import { CriteriaBarOutput } from "../../interfaces/student-pass-criteria-api";

@Component({
  selector: "criteria-bar",
  templateUrl: "./criteria-bar.component.html",
  styleUrls: ["./criteria-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CriteriaBarComponent implements OnInit {
  @Input() criteria: CriteriaBarOutput;
  @Input() isEditable: boolean;
  @Input() isDisabled: boolean = false;

  @Output() criteriaValues = new EventEmitter<CriteriaBarOutput>();
  @Output() editableChanged = new EventEmitter<boolean>();

  currentPartialGradeId$: Observable<string> = this.stats.currentPartialGradeId;
  selectedExercises$: Observable<string[]> = this.stats.currentItems;
  displayExercises: boolean = false;
  form: FormGroup = this.createForm();

  get is_active(): FormControl {
    return this.form.controls.is_active as FormControl<boolean>;
  }

  get exercises(): FormControl {
    return this.form.controls.exercises as FormControl<boolean>;
  }

  constructor(
    private fb: FormBuilder,
    private stats: StatsService,
  ) {}

  ngOnInit(): void {
    const hasExercises = this.criteria?.exercises?.length > 0;
    this.is_active.setValue(hasExercises);

    this.is_active.valueChanges.subscribe((isActive) => {
      this.isEditable = !!isActive;
      this.editableChanged.emit(this.isEditable);
      this.stats.changeSelectable(this.isEditable);

      if (this.isEditable) {
        this.stats.clearExercises();
      } else {
        this.criteriaValues.emit({
          exercises: [],
          name: this.criteria.name,
        });
      }
    });
  }

  createForm(): FormGroup {
    return this.fb.group({
      is_active: this.fb.control<boolean>(false),
      exercises: this.fb.control<string[]>([]),
    });
  }

  save(value: boolean, exercises: string[]): void {
    this.isEditable = value;
    this.editableChanged.emit(this.isEditable);
    this.stats.changeSelectable(this.isEditable);
    this.stats.changeHighlightable(false);

    if (value) {
      this.stats.clearExercises();
      this.criteria.exercises.forEach((exercise) =>
        this.stats.addItem(exercise),
      );
      this.stats.changeHighlightable(true);
    } else {
      this.criteriaValues.emit({
        exercises,
        name: this.criteria.name,
      });
    }
  }

  cancel(value: boolean): void {
    if (!this.criteria.exercises.length) {
      this.is_active.setValue(false);
    }

    if (!value) {
      this.isEditable = false;
      this.editableChanged.emit(false);
      this.stats.changeSelectable(false);
      this.stats.changeHighlightable(false);
    }
  }

  toggleEditable(value: boolean): void {
    this.isEditable = value;

    if (this.isEditable) {
      this.is_active.disable();
    } else {
      this.is_active.enable();
    }
    this.editableChanged.emit(value);
  }

  view(criteria: string): void {
    const isSameCriteria = this.criteria.name === criteria;
    this.displayExercises = !isSameCriteria;
    this.stats.setCurrentPartialGrade(
      isSameCriteria ? null : this.criteria.name,
    );
    this.stats.changeHighlightable(this.displayExercises);

    this.stats.clearExercises();
    if (this.displayExercises) {
      this.criteria.exercises.forEach((exercise) =>
        this.stats.addItem(exercise),
      );
    }
  }
}
