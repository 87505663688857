import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { StudentRatingSystemGradeEntity } from "../interfaces/student-rating-system-grade-entity";
import { StudentRatingSystemFilterFormData } from "../models/student-rating-system-filter-form-data";
import { StudentRatingSystemRecordValue } from "../interfaces/student-rating-system-record-value";
import { map } from "rxjs/operators";
import { GradeOperator } from "../../../../../shared/enums/grade-operator";
import {
  Grade,
  StudentPassCriteriaApi,
} from "src/app/shared/interfaces/student-pass-criteria-api";
import { PassCriteriaType } from "src/app/shared/enums/pass-criteria-type";
import { StudentStatElement } from "src/app/shared/interfaces/student";

@Injectable()
export class StudentRatingSystemFormService {
  private records: Map<number, StudentRatingSystemRecordValue> = new Map([
    [
      1,
      {
        label: "1",
        value: 50,
        prefix: GradeOperator.LESS_THAN,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      2,
      {
        label: "2",
        value: 50,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      2.5,
      {
        label: "2.5",
        value: 55,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      3,
      {
        label: "3",
        value: 60,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      3.5,
      {
        label: "3.5",
        value: 65,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      4,
      {
        label: "4",
        value: 70,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      4.5,
      {
        label: "4.5",
        value: 75,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      5,
      {
        label: "5",
        value: 80,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      5.5,
      {
        label: "5.5",
        value: 85,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        checkboxAvailable: true,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      6,
      {
        label: "6",
        value: 90,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        checkboxAvailable: true,
        isVisible: true,
        isActive: true,
      },
    ],
  ]);

  private readonly copy: Map<number, StudentRatingSystemRecordValue> = new Map(
    structuredClone(this.records),
  );

  private readonly entities$ = new BehaviorSubject<
    StudentRatingSystemGradeEntity[]
  >(this.buildEntities());
  private readonly data$ = new BehaviorSubject<
    StudentRatingSystemGradeEntity[]
  >([]);
  private currentGrades$ = new BehaviorSubject<Grade[]>([]);

  save(): void {
    this.data$.next(this.buildEntities());
  }

  update(data: StudentRatingSystemFilterFormData): void {
    const { grades } = data;

    grades.forEach((grade) => {
      const record: StudentRatingSystemRecordValue = this.records.get(grade.id);

      record.value = grade.range;
      record.prefix = grade.prefix;
      record.isActive = grade.active;
    });

    this.entities$.next(this.buildEntities());
  }

  get data(): Observable<StudentRatingSystemGradeEntity[]> {
    return this.data$;
  }

  clear(): void {
    this.data$.next([]);
  }

  showHalfGrades(): void {
    this.records.forEach(
      (record: StudentRatingSystemRecordValue, key: number) => {
        if (Number.isInteger(key)) {
          return;
        }

        record.isVisible = true;
        record.isActive = true;
      },
    );
  }

  showHalfGradesWithoutTwoAndFive(): void {
    const hiddenKeys = [2.5, 5.5];

    this.records.forEach(
      (record: StudentRatingSystemRecordValue, key: number) => {
        const shouldHide = hiddenKeys.includes(key);

        if (shouldHide) {
          record.isVisible = false;
          record.isActive = false;
        } else {
          record.isVisible = true;
        }
      },
    );
  }

  hideHalfGrades(): void {
    this.records.forEach(
      (record: StudentRatingSystemRecordValue, key: number) => {
        if (Number.isInteger(key)) {
          return;
        }

        record.isVisible = false;
      },
    );
  }

  showMaxMinGrades(): void {
    const showKeys = [1, 6];

    this.records.forEach(
      (record: StudentRatingSystemRecordValue, key: number) => {
        const shouldShow = showKeys.includes(key);

        if (shouldShow) {
          record.isVisible = true;
          record.isActive = true;
        }
      },
    );
  }

  hideMaxMinGrades(): void {
    const showKeys = [1, 6];

    this.records.forEach(
      (record: StudentRatingSystemRecordValue, key: number) => {
        const shouldShow = showKeys.includes(key);

        if (shouldShow) {
          record.isVisible = false;
          record.isActive = false;
        }
      },
    );
  }

  fetchAll(
    currentGrades: Grade[],
  ): Observable<StudentRatingSystemGradeEntity[]> {
    if (currentGrades.length) {
      this.currentGrades$.next(currentGrades);
      const entities = this.buildEntitiesWithInitGrades(currentGrades);
      this.entities$.next(entities);
    }

    return this.entities$.asObservable();
  }

  calculateTotalPoints(statistic: StudentStatElement): number {
    return statistic.topics.reduce((totalPoints, topic) => {
      return (
        totalPoints +
        topic.exercises.reduce(
          (points, exercise) => points + exercise.points,
          0,
        )
      );
    }, 0);
  }

  calculateMaxPoints(statistic: StudentStatElement): number {
    return statistic.topics.reduce((totalPoints, topic) => {
      return (
        totalPoints +
        topic.exercises.reduce(
          (max_points, exercise) => max_points + exercise.max_points,
          0,
        )
      );
    }, 0);
  }

  calculateTotalPercentage(passed: number, total: number): number {
    return total === 0 ? 0 : Math.floor((passed / total) * 100);
  }

  calculateGradeByPercentage(
    grades: StudentRatingSystemGradeEntity[],
    percentage: number,
  ): Observable<number> {
    return of(grades).pipe(
      map((grades) => grades.filter((grade) => grade.isActive)),

      map((activeGrades) => activeGrades.sort((a, b) => a.value - b.value)),

      map((sortedGrades) => {
        let selectedGrade: StudentRatingSystemGradeEntity | undefined =
          undefined;

        for (const grade of sortedGrades) {
          if (grade.value <= percentage) {
            selectedGrade = grade;
          } else {
            break;
          }
        }

        return selectedGrade
          ? selectedGrade.grade
          : sortedGrades[0]?.grade ?? null;
      }),
    );
  }

  buildCriteriaForApiUpdate(
    criteriaType: PassCriteriaType,
    halfGradesVisible: boolean,
    filterActive: boolean = false,
  ): StudentPassCriteriaApi {
    return {
      criteria_type: criteriaType,
      half_grades_visible: halfGradesVisible,
      grades: Array.from(this.records.entries())
        .filter(([key, entry]) => !filterActive || entry.isActive)
        .filter(([key]) => halfGradesVisible || !key.toString().includes(".5"))
        .map(([key, entry]) => ({
          grade: key,
          percent: entry.value,
          operator: entry.prefix,
          active: entry.isActive,
        })),
    };
  }

  updateCriteriaFromApiResponse(criteria: StudentPassCriteriaApi) {
    const { grades, half_grades_visible, criteria_type } = criteria;
    const formValueGrades: StudentRatingSystemFilterFormData = {
      grades: grades.map((gradeEntity) => {
        const {
          grade: id,
          active,
          percent: range,
          operator: prefix,
        } = gradeEntity;
        return {
          id,
          active,
          range,
          prefix,
        };
      }),
      pass_criteria: criteria_type,
      half_grades_visible,
    };

    if (half_grades_visible) {
      this.showHalfGrades();
    } else {
      this.hideHalfGrades();
    }

    this.update(formValueGrades);
    this.save();
  }

  private buildEntitiesWithInitGrades(
    currentGrades: Grade[],
  ): StudentRatingSystemGradeEntity[] {
    const activeGrades = new Set(currentGrades.map(({ grade }) => grade));

    return this.getFilteredEntities((key) => activeGrades.has(key));
  }

  private buildEntities(): StudentRatingSystemGradeEntity[] {
    return this.getFilteredEntities();
  }

  private getFilteredEntities(
    isActiveFn?: (key: number) => boolean,
  ): StudentRatingSystemGradeEntity[] {
    return Array.from(this.records.entries())
      .map(([grade, entry]) => ({
        grade,
        label: entry.label,
        value: entry.value,
        prefix: entry.prefix,
        isVisible: entry.isVisible,
        isActive: isActiveFn ? isActiveFn(grade) : entry.isActive,
        checkboxAvailable: entry.checkboxAvailable,
      }))
      .filter(({ isVisible }) => isVisible);
  }

  buildFromCopy(): void {
    this.records = new Map(structuredClone(this.copy));

    this.entities$.next(this.buildEntities());
  }
}
