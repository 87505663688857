import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AnswerResponse } from "../../../../interfaces/answer-response";
import { StudentStatElement } from "src/app/shared/interfaces/student";

@Injectable()
export class CourseExerciseAnswerListModalDataService {
  private readonly dataSource$ = new BehaviorSubject<AnswerResponse[]>([]);
  private readonly dataManualSource$ = new BehaviorSubject<AnswerResponse[]>(
    [],
  );

  get data(): AnswerResponse[] {
    return this.dataSource$.value;
  }

  get data$(): Observable<AnswerResponse[]> {
    return this.dataSource$.asObservable();
  }

  get dataManual$(): Observable<AnswerResponse[]> {
    return this.dataManualSource$.asObservable();
  }

  patch(output: AnswerResponse[]): void {
    this.dataSource$.next(output);
  }

  patchManual(
    output: AnswerResponse[],
    studentsList: StudentStatElement[],
  ): void {
    const filteredOutput = output.filter((item) => item.is_manual);
    const fileUserIds = new Set(output.map((file) => file.user.id));
    const filteredUsers = studentsList.filter(
      (user) => !fileUserIds.has(user.id),
    );

    const mappedFiles = filteredUsers.map((user) => ({
      filename: null,
      id: null,
      is_manual: true,
      is_reviewed: null,
      review: null,
      reviewed_at: null,
      s3_url: null,
      uploaded_at: null,
      user: {
        id: user.id,
        name: user.name,
        email: user.email,
      },
    }));

    const finalMappedReviews = [...filteredOutput, ...mappedFiles];
    this.dataManualSource$.next(finalMappedReviews);
  }

  clear(): void {
    this.dataSource$.next([]);
  }
}
