import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { StudentPassCriteriaApi } from "src/app/shared/interfaces/student-pass-criteria-api";
import {
  statsCriteriaAllEditLoad,
  statsCriteriaEditAllLoadSuccess,
  statsCriteriaEditAllLoadFailure,
} from "../../actions/stats/stats-crtieria-all-edit.actions";

export interface StatsCriteriaAllEditState {
  criteria: StudentPassCriteriaApi | null;
  error: HttpErrorResponse | null;
}

const initialState: StatsCriteriaAllEditState = {
  criteria: null,
  error: null,
};

export const createStatsCriteriaAllEditReducer = createReducer(
  initialState,
  on(
    statsCriteriaAllEditLoad,
    (state): StatsCriteriaAllEditState => ({
      ...state,
      criteria: null,
      error: null,
    }),
  ),
  on(
    statsCriteriaEditAllLoadSuccess,
    (state, action): StatsCriteriaAllEditState => ({
      ...state,
      criteria: action.criteria,
      error: null,
    }),
  ),
  on(statsCriteriaEditAllLoadFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
