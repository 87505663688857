import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from "@angular/core";
import { ModalComponent } from "../../modal/modal.component";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "flag-exercise-modal",
  templateUrl: "./flag-exercise-modal.component.html",
  styleUrls: ["./flag-exercise-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagExerciseModalComponent extends ModalComponent {
  @Output() onConfirm = new EventEmitter<string>();
  message: FormControl<string> = new FormControl("", [
    Validators.required,
    Validators.maxLength(255),
  ]);

  confirm(): void {
    this.onConfirm.emit(this.message.value);
    this.close.emit("close");
  }

  cancel(): void {
    this.close.emit("close");
  }
}
