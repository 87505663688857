import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { StudentPassCriteriaApi } from "src/app/shared/interfaces/student-pass-criteria-api";

export const prefix = "[Stats Criteria]";

const statsCriteriaActions = {
  request: `${prefix} Load`,
  requestActiveSuccess: `${prefix} Load success`,
  requestFail: `${prefix} Load fail`,
};

export const statsCriteriaLoad = createAction(
  statsCriteriaActions.request,
  props<{
    CourseId: string;
    GroupId: string;
  }>(),
);

export const statsCriteriaLoadSuccess = createAction(
  statsCriteriaActions.requestActiveSuccess,
  props<{ criteria: StudentPassCriteriaApi }>(),
);

export const statsCriteriaLoadFailure = createAction(
  statsCriteriaActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
