import { Component, Input } from "@angular/core";

@Component({
  selector: "round-badge",
  templateUrl: "./round-badge.component.html",
  styleUrls: ["./round-badge.component.scss"],
})
export class RoundBadgeComponent {
  @Input() size?: "small" | "large" = "small";
  @Input() color?: "primary" | "blue-light" = "primary";
}
