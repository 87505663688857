<form [formGroup]="quizForm">
  <div *ngFor="let question of quizQuestions; let i = index">
    <div class="quiz-question">
      <div class="d-flex flex-row justify-content-between">
        <div class="d-flex flex-row align-items-center">
          <h6 class="quiz-title pr-4">
            {{ "QUIZ.QUESTION" | translate }} {{ i + 1 }}
          </h6>
          <label class="quiz-caption">
            {{
              question.multi_choice
                ? ("QUIZ.EXERCISE.QUESTION_MULTIPLE_ANSWERS" | translate)
                : ("QUIZ.EXERCISE.QUESTION_SINGLE_ANSWER" | translate)
            }}
          </label>
        </div>
        <h6 class="quiz-title">
          {{ maxPoints }} {{ "GLOBAL.POINTS" | translate }}
        </h6>
      </div>

      <h6 class="quiz_desc pt-4">
        {{ question.content }}
      </h6>

      <div
        class="mt-3 mb-3 d-flex justify-content-center"
        *ngIf="question.figure.length"
      >
        <img
          class="figure-img"
          alt="{{ question.content }}"
          [src]="question.figure"
        />
      </div>

      <rich-text class="explanation" [content]="question.explanation" />

      <div class="d-flex flex-row justify-content-start">
        <quiz-answer-choice
          [question]="question"
          [formArray]="quizForm.get('answers.' + i + '.answer')"
        />
      </div>
    </div>
  </div>
</form>
