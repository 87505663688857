import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  Config,
  CriteriaBarPayload,
} from "src/app/shared/interfaces/student-pass-criteria-api";

export const prefix = "[Stats Criteria Edit]";

const statsCriteriaEditActions = {
  request: `${prefix} Load`,
  requestActiveSuccess: `${prefix} Load success`,
  requestFail: `${prefix} Load fail`,
};

export const statsCriteriaEditLoad = createAction(
  statsCriteriaEditActions.request,
  props<{
    CourseId: string;
    GroupId: string;
    criteria: CriteriaBarPayload;
    GarlicId: string;
  }>(),
);

export const statsCriteriaEditLoadSuccess = createAction(
  statsCriteriaEditActions.requestActiveSuccess,
  props<{ criteria: Config }>(),
);

export const statsCriteriaEditLoadFailure = createAction(
  statsCriteriaEditActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
