import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ModalComponent } from "../../modal/modal.component";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { QuizSettingsForm } from "./quiz-settings-form.model";
import { Store } from "@ngrx/store";
import * as fromCourse from "src/app/store/actions/course.actions";

@Component({
  selector: "course-quiz-settings-modal",
  templateUrl: "./course-quiz-settings-modal.component.html",
  styleUrls: ["./course-quiz-settings-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseQuizSettingsModalComponent
  extends ModalComponent
  implements OnInit
{
  form: FormGroup<QuizSettingsForm> = this.initForm();

  timeLimitControl: FormControl<boolean> = new FormControl<boolean>(true);
  passMarkControl: FormControl<boolean> = new FormControl<boolean>(true);
  maxAttemptsControl: FormControl<boolean> = new FormControl<boolean>(true);
  historyControl: FormControl<boolean> = new FormControl<boolean>(false);
  selectedAnswersControl: FormControl<boolean> = new FormControl<boolean>(
    false,
  );
  correctAnswersControl: FormControl<boolean> = new FormControl<boolean>(false);

  get timeLimit(): FormControl {
    return this.form.controls.time_limit;
  }

  get passMark(): FormControl {
    return this.form.controls.pass_mark;
  }

  get maxAttempts(): FormControl {
    return this.form.controls.max_attempts;
  }

  constructor(
    private fb: FormBuilder,
    private store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupCheckboxListeners();
    this.initFormValues();
  }

  initForm(): FormGroup<QuizSettingsForm> {
    return this.fb.group<QuizSettingsForm>({
      time_limit: this.fb.control({ value: null, disabled: false }, [
        Validators.required,
        Validators.min(1),
      ]),
      pass_mark: this.fb.control({ value: null, disabled: false }, [
        Validators.required,
        Validators.min(1),
      ]),
      max_attempts: this.fb.control({ value: null, disabled: false }, [
        Validators.required,
        Validators.min(1),
      ]),
    });
  }

  disableScroll(event: WheelEvent): void {
    event.preventDefault();
  }

  private initFormValues(): void {
    const { exercise } = this.data;
    const properties = [
      { property: "time_limit", control: this.timeLimitControl },
      { property: "pass_mark", control: this.passMarkControl },
      { property: "max_attempts", control: this.maxAttemptsControl },
    ];

    properties.forEach(({ property, control }) => {
      if (!exercise.quiz_properties[property]) {
        control.setValue(false);
      }
    });

    this.timeLimit.setValue(exercise.quiz_properties.time_limit);
    this.passMark.setValue(exercise.quiz_properties.pass_mark);
    this.maxAttempts.setValue(exercise.quiz_properties.max_attempts);
    this.historyControl.setValue(exercise.quiz_properties.has_solutions);
    this.selectedAnswersControl.setValue(
      exercise.quiz_properties.show_selected_answers,
    );
    this.correctAnswersControl.setValue(
      exercise.quiz_properties.show_correct_answers,
    );

    this.passMark.addValidators([
      Validators.max(exercise.quiz_properties.max_points),
    ]);
    this.passMark.updateValueAndValidity();
  }

  private setupCheckboxListeners(): void {
    const { exercise } = this.data;

    this.timeLimitControl.valueChanges.subscribe((isEnabled: boolean) => {
      if (isEnabled) {
        this.timeLimit.setValue(exercise.quiz_properties.time_limit);
        this.timeLimit.enable();
      } else {
        this.timeLimit.setValue(null);
        this.timeLimit.disable();
      }
    });

    this.passMarkControl.valueChanges.subscribe((isEnabled: boolean) => {
      if (isEnabled) {
        this.passMark.setValue(exercise.quiz_properties.pass_mark);
        this.passMark.enable();
      } else {
        this.passMark.setValue(null);
        this.passMark.disable();
      }
    });

    this.maxAttemptsControl.valueChanges.subscribe((isEnabled: boolean) => {
      if (isEnabled) {
        this.maxAttempts.setValue(exercise.quiz_properties.max_attempts);
        this.maxAttempts.enable();
      } else {
        this.maxAttempts.setValue(null);
        this.maxAttempts.disable();
      }
    });

    this.historyControl.valueChanges.subscribe((isEnabled: boolean) => {
      if (isEnabled) {
        this.selectedAnswersControl.enable();
        this.correctAnswersControl.enable();
      } else {
        this.selectedAnswersControl.disable();
        this.correctAnswersControl.disable();
      }
    });
  }

  sendForm(): void {
    const { CourseId, exercise, GroupId, TopicId } = this.data;

    this.store.dispatch(
      new fromCourse.SelectUnselectExercise({
        selection: {
          CourseId,
          GroupId,
          TopicId,
          exercise,
        },
        selectionParam: {
          new_quiz_properties: {
            has_solutions: this.historyControl.value,
            show_selected_answers: this.selectedAnswersControl.value,
            show_correct_answers: this.correctAnswersControl.value,
            time_limit: this.timeLimit.value ? this.timeLimit.value : null,
            pass_mark: this.passMark.value ? this.passMark.value : null,
            max_attempts: this.maxAttempts.value
              ? this.maxAttempts.value
              : null,
          },
        },
        displayModal: true,
      }),
    );

    this.close.emit("confirm");
    this.closeModal();
  }

  closeModal(): void {
    this.close.emit("close");
  }

  preventNegative(event: KeyboardEvent): void {
    if (event.key === "-" || event.key === "e") {
      event.preventDefault();
    }
  }
}
