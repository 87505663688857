import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ExerciseControl } from "../../../interfaces/exercise-control";
import { HelpRequest } from "../../../interfaces/help-request";
import { Topic } from "../../../interfaces/topic";
import { AnswerPayload } from "../../../interfaces/answer-payload";
import { Guid } from "src/app/shared/types/guid";
import { Exercise } from "../../../interfaces/exercise";
import { ExerciseSection } from "../../../enums/exercise-section";
import { isEmpty, isNil } from "lodash-es";
import { Observable } from "rxjs";
import { TutorialTeacherSteps } from "src/app/shared/enums/tutorial-steps";
import { Store } from "@ngrx/store";
import * as fromTutorial from "src/app/store/reducers/tutorial.reducer";
import { StudentStatElement } from "src/app/shared/interfaces/student";

@Component({
  selector: "course-topic",
  templateUrl: "./course-topic.component.html",
  styleUrls: ["./course-topic.component.scss"],
})
export class CourseTopicComponent implements OnChanges {
  @Input() currentTopic: Topic;
  @Input() topics: Array<Topic>;
  @Input() courseId: Guid;
  @Input() topicId: Guid;
  @Input() activeGroup: Guid;
  @Input() individualSubtitleLabel!: string;
  @Input() studentsList: Array<StudentStatElement>;

  @Output()
  startExercise: EventEmitter<ExerciseControl> =
    new EventEmitter<ExerciseControl>();
  @Output()
  stopExercise: EventEmitter<ExerciseControl> =
    new EventEmitter<ExerciseControl>();
  @Output()
  getHelp: EventEmitter<HelpRequest> = new EventEmitter<HelpRequest>();
  @Output()
  getVideoHelp: EventEmitter<HelpRequest> = new EventEmitter<HelpRequest>();

  @Output()
  answer: EventEmitter<AnswerPayload> = new EventEmitter<AnswerPayload>();

  @Input("teacher") isTeacher = false;

  tutorial$: Observable<TutorialTeacherSteps> = this.store.select(
    (state) => state.tutorial.tutorial_step,
  );

  TutorialTeacherSteps = TutorialTeacherSteps;
  exercises: Exercise[] = [];
  individualExercises: Exercise[] = [];

  readonly ExerciseSection = ExerciseSection;

  readonly tutorialSteps: Array<{
    step: TutorialTeacherSteps;
    title: string;
    message: string;
  }> = [
    {
      step: TutorialTeacherSteps.COURSE_ACTIONS_BTN,
      title: "COURSE_ACTIONS_LABEL",
      message: "COURSE_ACTIONS",
    },
    {
      step: TutorialTeacherSteps.COURSE_SINGLE_BTN,
      title: "COURSE_SINGLE_LABEL",
      message: "COURSE_SINGLE",
    },
    {
      step: TutorialTeacherSteps.COURSE_EXPAND_BTN,
      title: "COURSE_EXPAND_LABEL",
      message: "COURSE_EXPAND",
    },
    {
      step: TutorialTeacherSteps.COURSE_VIDEO_BTN,
      title: "COURSE_VIDEO_LABEL",
      message: "COURSE_VIDEO",
    },
    {
      step: TutorialTeacherSteps.COURSE_START_BTN,
      title: "COURSE_START_LABEL",
      message: "COURSE_START",
    },
  ];

  readonly tutorialStepsRelatedToExercise: Array<TutorialTeacherSteps> = [
    TutorialTeacherSteps.COURSE_ACTIONS_BTN,
    TutorialTeacherSteps.COURSE_SINGLE_BTN,
    TutorialTeacherSteps.COURSE_EXPAND_BTN,
    TutorialTeacherSteps.COURSE_VIDEO_BTN,
    TutorialTeacherSteps.COURSE_START_BTN,
  ];

  constructor(
    private store: Store<{
      tutorial: fromTutorial.TutorialState;
    }>,
  ) {}

  get isIndividualListEmpty(): boolean {
    return isEmpty(this.individualExercises);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentTopic } = changes;

    if (currentTopic) {
      this.exercises = this.prepareExercises();
      this.individualExercises = this.prepareIndividualExercises();
    }
  }

  private prepareExercises(): Exercise[] {
    const { exercises } = this.currentTopic;

    return exercises.filter((exercise) => isNil(exercise.section));
  }

  private prepareIndividualExercises(): Exercise[] {
    const { exercises } = this.currentTopic;

    return exercises.filter(
      (exercise) => exercise.section === ExerciseSection.INDIVIDUAL,
    );
  }

  isCurrentTutorialStep(tutorial_step: TutorialTeacherSteps): boolean {
    return this.tutorialStepsRelatedToExercise.includes(tutorial_step);
  }
}
