<ng-container *ngTemplateOutlet="headerTemplateRef" />
<div
  *ngIf="{
    id: currentPartialGrade$ | async,
    selectable: selectableExercises$ | async,
    highlightable: highlightableExercises$ | async,
    selectedExercises: selectedExercises$ | async
  } as exercises"
  class="stat-datatable-wrapper"
>
  <table class="stat-datatable">
    <thead class="stat-datatable-thead">
      <ng-container
        *ngIf="criteria?.criteria_type === PassCriteriaType.PARTIAL_GRADES"
      >
        <ng-container *ngFor="let config of criteria.configs">
          <tr *ngIf="config.is_active && config.grades.length > 0">
            <th
              *ngIf="isTeacher && !isOrgAdmin"
              class="flagged-history-column"
            ></th>
            <th class="datatable-column-sticky">{{ config.name }}</th>
            <ng-container *ngFor="let column of users">
              <th
                [ngClass]="{
                  'bg-red': gradeOneIsAvailable
                    ? getLastGrade(getGrades(config, column))?.grade == 1
                    : getLastGrade(getGrades(config, column))?.grade == 2,
                  'bg-green': gradeOneIsAvailable
                    ? getLastGrade(getGrades(config, column))?.grade >= 2
                    : getLastGrade(getGrades(config, column))?.grade > 2
                }"
              >
                <div class="d-flex justify-content-center align-items-baseline">
                  <span
                    class="color-hwhite"
                    *ngIf="getLastGrade(getGrades(config, column)) as lastGrade"
                  >
                    {{ lastGrade.grade }}
                  </span>
                  <div class="button-container">
                    <button
                      type="button"
                      (click)="toggleGradesHistory(config.id, column.id)"
                      cdkOverlayOrigin
                      #trigger="cdkOverlayOrigin"
                    >
                      <icon
                        class="button-icon color-hlightgray"
                        name="history"
                        set="uil"
                      ></icon>
                    </button>
                  </div>

                  <ng-template
                    cdkConnectedOverlay
                    [cdkConnectedOverlayOrigin]="trigger"
                    [cdkConnectedOverlayOpen]="
                      gradesHistoryIsOpen &&
                      currentGradeId === config.id &&
                      currentUserId === column.id
                    "
                  >
                    <div class="overlay-history">
                      <span class="pb-1 overlay-title">
                        {{ "COURSES.COURSE.GRADES_HISTORY" | translate }}
                      </span>
                      <div
                        class="text-center color-hdarkgray pb-1"
                        *ngFor="let grade of getGrades(config, column)"
                      >
                        <span>
                          {{ grade.created_at }} -
                          {{ grade.grade }}
                        </span>
                        <span *ngIf="grade.reason === 2">
                          ({{ "COURSES.COURSE.GRADE_EDITED" | translate }})
                        </span>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </th>
            </ng-container>
          </tr>
        </ng-container>
      </ng-container>

      <tr>
        <th
          *ngIf="isTeacher && !isOrgAdmin"
          class="datatable-column-sticky flagged-history-column"
        >
          <span
            class="uil uil-map-marker-question history-icon"
            [tooltip]="'COURSES.COURSE.EXERCISE.FLAGGED_EXERCISES' | translate"
          ></span>
        </th>
        <th class="datatable-column-sticky">
          <button
            type="button"
            class="row-expandable-action"
            (click)="rowsExpander.toggleExpandAllState()"
          >
            <span class="table-label">
              {{ "COURSES.COURSE.EXPAND_ALL_ACTION" | translate }}
            </span>

            <span
              class="row-expandable-action-icon"
              [class.row-expandable-action--expanded]="
                rowsExpander.isAllExpanded
              "
            >
              <icon name="chevron-right" />
            </span>
          </button>
        </th>

        <ng-container *ngFor="let column of users">
          <th>
            <span class="table-label">
              {{
                isTeacher
                  ? column.name
                  : ("COURSES.COURSE.STATS_POINTS" | translate)
              }}
            </span>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody class="stat-datatable-tbody">
      <ng-container *ngFor="let row of rows; let i = index">
        <tr class="row-expandable">
          <td *ngIf="isTeacher && !isOrgAdmin" class="datatable-column-sticky">
            <div class="d-flex align-items-center justify-content-center">
              <span class="mr-1">{{ calculateTotalRequestsInTopic(row) }}</span>
              <span
                *ngIf="calculateTotalRequestsInTopic(row) >= 1"
                class="uil uil-map-marker-question history-icon"
                (click)="handleOpenCourseHistory(row)"
              ></span>
            </div>
          </td>

          <td class="datatable-column-sticky">
            <button
              type="button"
              class="row-expandable-action"
              (click)="rowsExpander.toggleExpandState(i)"
            >
              <span class="table-label">{{ row.name }}</span>

              <span
                class="row-expandable-action-icon"
                [class.row-expandable-action--expanded]="
                  rowsExpander.isExpanded(i)
                "
              >
                <icon name="chevron-right" />
              </span>
            </button>
          </td>

          <ng-container *ngFor="let result of row.topicSummary">
            <td>{{ result.completed }} / {{ result.all }}</td>
          </ng-container>
        </tr>

        <ng-container *ngFor="let exercise of row.exercises">
          <tr *ngIf="rowsExpander.isExpanded(i)">
            <td *ngIf="isTeacher && !isOrgAdmin">
              <div
                class="d-flex align-items-center justify-content-center"
                [ngClass]="{
                  'none-requests':
                    calculateTotalRequestsInExercise(exercise) < 1
                }"
              >
                <span class="mr-1">
                  {{ calculateTotalRequestsInExercise(exercise) }}
                </span>
                <span
                  *ngIf="calculateTotalRequestsInExercise(exercise) >= 1"
                  class="uil uil-map-marker-question history-icon history-icon--exercise"
                  (click)="handleOpenExerciseHistory(exercise)"
                ></span>
              </div>
            </td>
            <td
              class="datatable-column-sticky"
              [ngClass]="{
                'stat-row':
                  exercises.highlightable &&
                  isSelectedExercise(exercise, exercises.selectedExercises)
              }"
            >
              <span class="table-label table-label--display ml-4">
                <span class="d-flex justify-content-center table-checkbox">
                  <input
                    *ngIf="exercises.selectable"
                    type="checkbox"
                    class="multi-show"
                    [checked]="
                      isSelectedExercise(exercise, exercises.selectedExercises)
                    "
                    (change)="handleSetExercises(exercise, exercises.id)"
                  />
                </span>
                <span>{{ exercise.name }}</span>
              </span>
            </td>

            <ng-container *ngFor="let result of exercise.exerciseSummary">
              <td
                [ngClass]="{
                  'stat-row':
                    exercises.highlightable &&
                    isSelectedExercise(exercise, exercises.selectedExercises)
                }"
                [class]="
                  'cell-status--' + (result | studentExerciseCellClass | async)
                "
              >
                <span class="mr-2">
                  {{ result.points }}
                </span>
                <ng-container
                  *ngIf="
                    result.exercise.answer_type ===
                      AnswerType.ANSWER_TYPE_CODE &&
                    result.passedTime &&
                    !isOrgAdmin
                  "
                >
                  <code-check-history-button
                    class="code-check-history-button small text-color-gray-400 bg-unset"
                    [teacher]="isTeacher"
                    [courseId]="courseId"
                    [exercise]="result.exercise"
                  />
                </ng-container>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </ng-container>

      <tr>
        <td *ngIf="isTeacher && !isOrgAdmin"></td>
        <td class="datatable-column-sticky">
          {{ "COURSES.COURSE.TOTAL_POINTS" | translate }}
        </td>

        <ng-container *ngFor="let statistic of statistics">
          <td>{{ statistic | studentExerciseTotalPoints }}</td>
        </ng-container>
      </tr>

      <tr>
        <td *ngIf="isTeacher && !isOrgAdmin"></td>

        <td class="datatable-column-sticky">
          {{ "COURSES.COURSE.TOTAL" | translate }}
        </td>

        <ng-container
          *ngIf="
            criteria && criteria.config?.grades?.length > 0;
            else thresholdStatistics
          "
        >
          <ng-container *ngFor="let user of usersWithGrade">
            <td
              [ngClass]="{
                'bg-red': user.grades[user.grades.length - 1]?.passed === false,
                'bg-green': user.grades[user.grades.length - 1]?.passed === true
              }"
            >
              {{ user.grades[user.grades.length - 1].score }}%
            </td>
          </ng-container>
        </ng-container>
      </tr>

      <ng-template #thresholdStatistics>
        <ng-container *ngFor="let statistic of statistics">
          <td
            [class]="
              criteria
                ? 'total-cell-status total-cell-status--' +
                  (statistic | studentExercisePercentageCellClass | async)
                : ''
            "
          >
            {{ statistic | studentExercisePercentage }}%
          </td>
        </ng-container>
      </ng-template>

      <tr *ngIf="criteria?.criteria_type === PassCriteriaType.GRADES">
        <td *ngIf="isTeacher && !isOrgAdmin"></td>

        <td class="datatable-column-sticky">
          {{ "COURSES.COURSE.GRADE" | translate }}
        </td>

        <ng-container
          *ngIf="
            criteria && criteria.config?.grades?.length > 0;
            else gradeStatistics
          "
        >
          <ng-container *ngFor="let user of usersWithGrade">
            <td
              [ngClass]="{
                'bg-red': gradeOneIsAvailable
                  ? user.grades[user.grades.length - 1].grade === 1
                  : user.grades[user.grades.length - 1].grade === 2,
                'bg-green': gradeOneIsAvailable
                  ? user.grades[user.grades.length - 1].grade >= 2
                  : user.grades[user.grades.length - 1].grade >= 3
              }"
            >
              {{ user.grades[user.grades.length - 1].grade }}
            </td>
          </ng-container>
        </ng-container>

        <ng-template #gradeStatistics>
          <ng-container *ngFor="let statistic of statistics">
            <td
              *ngIf="records$ | async as records"
              [class]="
                'grade-cell-status grade-cell-status--' +
                (statistic | studentExerciseGradeCellClass: records | async)
              "
            >
              {{ statistic | studentExerciseGrade: records | async | noData }}
            </td>
          </ng-container>
        </ng-template>
      </tr>
    </tbody>
  </table>
</div>
