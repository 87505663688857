import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  inject,
} from "@angular/core";
import { TabPanelComponent } from "./components/tab-panel/tab-panel.component";
import { Nillable } from "../../types/nillable";
import { isEmpty, isNil } from "lodash-es";
import { Observable, combineLatest, of } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { SelectItem } from "../dropdown/models/select-item";
import { StudentPassConditionTabName } from "src/app/modules/teacher/components/student-pass-condition/enums/student-pass-condition-tab-name";
import { TranslateService } from "@ngx-translate/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "tab-view",
  templateUrl: "./tab-view.component.html",
  styleUrls: ["./tab-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabViewComponent implements OnChanges, AfterContentInit {
  @Output() onChangeEvent = new EventEmitter<number>();
  @Input() initTab: number | null;

  private _disabled: Nillable<boolean> = false;

  @ContentChildren(TabPanelComponent) tabPanels: QueryList<
    Nillable<TabPanelComponent>
  >;

  private readonly translate = inject(TranslateService);

  criteriaControl = new FormControl<number | null>(null);
  criteriaOptions$: Observable<SelectItem<StudentPassConditionTabName>[]> =
    this.criteriaFormOptions();

  ngOnChanges(changes: SimpleChanges): void {
    const { initTab } = changes;

    if (initTab.currentValue) {
      this.criteriaControl.patchValue(this.initTab);
    }
  }

  ngAfterContentInit(): void {
    this.configureActiveTabPanel();
  }

  handleTabClick(option: SelectItem<StudentPassConditionTabName>): void {
    this.onChangeEvent.emit(option.value);
  }

  private findSelectedTabPanel(): Observable<Nillable<TabPanelComponent>> {
    return combineLatest(
      Array.from(this.tabPanels).map((tab) => tab.isSelected),
    ).pipe(
      switchMap((tabs) => {
        const index = tabs.findIndex((selected) => selected);
        return index > -1
          ? of(Array.from(this.tabPanels)[index])
          : of(undefined);
      }),
      take(1),
    );
  }

  private configureActiveTabPanel(): void {
    this.findSelectedTabPanel().subscribe((firstSelected) => {
      const tabs = Array.from(this.tabPanels);

      if (!(isNil(firstSelected) && !isEmpty(tabs))) {
        return;
      }

      const firstEnabled = tabs.find((tab) => !tab.disabled);

      if (isNil(firstEnabled)) {
        const [firstDisabled] = tabs;

        firstDisabled.selected = true;

        return;
      }

      firstEnabled.selected = true;
    });
  }

  private deselectTabPanel(): void {
    this.findSelectedTabPanel().subscribe((selectedTab) => {
      if (isNil(selectedTab)) {
        return;
      }

      selectedTab.selected = false;
    });
  }

  private criteriaFormOptions(): Observable<
    SelectItem<StudentPassConditionTabName>[]
  > {
    return of([
      new SelectItem(
        this.translate.instant("COURSES.COURSE.NONE_COURSE"),
        StudentPassConditionTabName.NONE,
      ),
      new SelectItem(
        this.translate.instant("COURSES.COURSE.PASS"),
        StudentPassConditionTabName.PASS,
      ),
      new SelectItem(
        this.translate.instant("COURSES.COURSE.RATING_SYSTEM"),
        StudentPassConditionTabName.RATING_SYSTEM,
      ),
    ]);
  }
}
