import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[disableWheel]",
})
export class DisableWheelDirective {
  @HostListener("wheel", ["$event"])
  onWheel(event: WheelEvent): void {
    event.preventDefault();
  }
}
