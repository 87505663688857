import { HttpErrorResponse } from "@angular/common/http";
import { Action } from "@ngrx/store";
import { AnswerPayload } from "src/app/shared/interfaces/answer-payload";
import { Course } from "src/app/shared/interfaces/course";
import { CourseGroup } from "src/app/shared/interfaces/course-group";
import { Exercise } from "src/app/shared/interfaces/exercise";
import { ExerciseControl } from "src/app/shared/interfaces/exercise-control";
import {
  ExerciseSelection,
  ExerciseSelectionParams,
} from "src/app/shared/interfaces/exercise-selection";
import { HelpRequest } from "src/app/shared/interfaces/help-request";
import { Hint } from "src/app/shared/interfaces/hint";
import {
  StatElement,
  StudentStatElement,
} from "src/app/shared/interfaces/student";
import { Topic } from "src/app/shared/interfaces/topic";
import { TopicSelection } from "src/app/shared/interfaces/topic-selection";
import { VideoHint } from "src/app/shared/interfaces/video-hint";
import { Guid } from "src/app/shared/types/guid";

export const GET_COURSE = "[Course Method] GetCourse";
export const GET_COURSE_BY_GROUP = "[Course Method] GetCourseByGroup";
export const GET_COURSE_COMPLETED = "[Course Method] GetCourseCompleted";

export const GET_COURSE_STATISTICS = "[Course Method] GetCourseStatistics";
export const GET_COURSE_STATISTICS_COMPLETED =
  "[Course Method] GetCourseStatisticsCompleted";
export const GET_TOPIC_STATISTICS = "[Course Method] GetTopicStatistics";
export const GET_TOPIC_STATISTICS_COMPLETED =
  "[Course Method] GetTopicStatisticsCompleted";

export const EXPORT_COURSE_STATISTICS =
  "[Course Method] ExportCourseStatistics";
export const EXPORT_COURSE_STATISTICS_COMPLETED =
  "[Course Method] ExportCourseStatisticsCompleted";
export const EXPORT_COURSE_STATISTICS_FAILED =
  "[Course Method] ExportCourseStatisticsFailed";

export const DOWNLOAD_INDIVIDUAL_REPORT =
  "[Course Method] DownloadIndividualCourseReport";
export const DOWNLOAD_INDIVIDUAL_REPORT_SUCCESS =
  "[Course Method] DownloadIndividualCourseReportSuccess";
export const DOWNLOAD_INDIVIDUAL_REPORT_FAILED =
  "[Course Method] DownloadIndividualCourseReportFailed";

export const DOWNLOAD_GROUP_REPORT =
  "[Course Method] DownloadGroupCourseReport";
export const DOWNLOAD_GROUP_REPORT_SUCCESS =
  "[Course Method] DownloadGroupCourseReportSuccess";
export const DOWNLOAD_GROUP_REPORT_FAILED =
  "[Course Method] DownloadGroupCourseReportFailed";
export const DOWNLOAD_GROUP_REPORT_RESTART =
  "[Course Method] DownloadGroupCourseReportRestart";

export const SET_ACTIVE_GROUP = "[Course Method] SetActiveGroup";
export const SET_CURRENT_TOPIC = "[Course Method] SetCurrentTopic";
export const GET_COURSE_FAILED = "[Course Method] GetCourseFailed";

export const GET_HELP = "[Course Method] GetHelp";
export const GET_VIDEO_HELP = "[Course Method] GetVideoHelp";
export const GET_HELP_COMPLETED = "[Course Method] GetHelpCompleted";
export const GET_VIDEO_HELP_COMPLETED = "[Course Method] GetVideoHelpCompleted";

export const START_EXERCISE = "[Course Method] StartExercise";
export const START_TEACHER_EXERCISE = "[Course Method] StartTeacherExercise";
export const START_EXERCISE_COMPLETED =
  "[Course Method] StartExerciseCompleted";
export const START_EXERCISE_FAILED = "[Course Method] StartExerciseFailed";
export const STOP_EXERCISE = "[Course Method] StopExercise";
export const STOP_TEACHER_EXERCISE = "[Course Method] StopTeacherExercise";
export const STOP_EXERCISE_COMPLETED = "[Course Method] StopExerciseCompleted";
export const STOP_EXERCISE_FAILED = "[Course Method] StopExerciseFailed";

export const SELECT_UNSELECT_EXERCISE =
  "[Course Method] SelectUnselectExercise";
export const SELECT_UNSELECT_EXERCISE_COMPLETED =
  "[Course Method] SelectUnselectExerciseCompleted";
export const SELECT_UNSELECT_ALL = "[Course Method] SelectUnselectAll";
export const SELECT_UNSELECT_ALL_COMPLETED =
  "[Course Method] SelectUnselectAllCompleted";

export const ANSWER = "[Course Method] Answer";
export const ANSWER_COMPLETED = "[Course Method] AnswerCompleted";
export const ANSWER_COMPLETED_WRONG = "[Course Method] AnswerCompletedWrong";

export class GetCourse implements Action {
  readonly type = GET_COURSE;

  constructor(public payload: { CourseId: Guid }) {}
}

export class GetCourseByGroup implements Action {
  readonly type = GET_COURSE_BY_GROUP;

  constructor(
    public payload: { CourseId: Guid; GroupId: Guid },
    public pure: boolean = false,
  ) {}
}

export class GetCourseCompleted implements Action {
  readonly type = GET_COURSE_COMPLETED;

  constructor(public payload: CourseGroup | Course) {}
}

export class SetActiveGroup implements Action {
  readonly type = SET_ACTIVE_GROUP;

  constructor(public payload: Guid) {}
}

export class SetCurrentTopic implements Action {
  readonly type = SET_CURRENT_TOPIC;

  constructor(
    public payload: { Topic: Topic; CourseId: Guid; GroupId: Guid },
  ) {}
}

export class GetCourseFailed implements Action {
  readonly type = GET_COURSE_FAILED;
}

export class GetCourseStatistics implements Action {
  readonly type = GET_COURSE_STATISTICS;

  constructor(public payload: { CourseId: Guid; GroupId: Guid }) {}
}

export class GetCourseStatisticsCompleted implements Action {
  readonly type = GET_COURSE_STATISTICS_COMPLETED;
  constructor(public payload: StatElement) {}
}

export class GetTopicStatistics implements Action {
  readonly type = GET_TOPIC_STATISTICS;

  constructor(
    public payload: { CourseId: Guid; GroupId: Guid; TopicId: Guid },
  ) {}
}

export class GetTopicStatisticsCompleted implements Action {
  readonly type = GET_TOPIC_STATISTICS_COMPLETED;

  constructor(public payload: Array<StudentStatElement>) {}
}

export class ExportCourseStatistics implements Action {
  readonly type = EXPORT_COURSE_STATISTICS;

  constructor(
    public payload: {
      CourseId: Guid;
      GroupId: Guid;
      CourseName: string;
      GroupName: string;
    },
  ) {}
}

export class ExportCourseStatisticsCompleted implements Action {
  readonly type = EXPORT_COURSE_STATISTICS_COMPLETED;
}

export class ExportCourseStatisticsFailed implements Action {
  readonly type = EXPORT_COURSE_STATISTICS_FAILED;

  constructor(public error: HttpErrorResponse) {}
}

export class DownloadIndividualReport implements Action {
  readonly type = DOWNLOAD_INDIVIDUAL_REPORT;

  constructor(
    public payload: {
      CourseId: Guid;
      GroupId: Guid;
      UserId: Guid;
      UserName: string;
    },
  ) {}
}

export class DownloadIndividualReportSuccess implements Action {
  readonly type = DOWNLOAD_INDIVIDUAL_REPORT_SUCCESS;
}

export class DownloadIndividualReportFailed implements Action {
  readonly type = DOWNLOAD_INDIVIDUAL_REPORT_FAILED;
}

export class DownloadGroupReport implements Action {
  readonly type = DOWNLOAD_GROUP_REPORT;

  constructor(
    public payload: {
      CourseId: Guid;
      GroupId: Guid;
      GroupName: string;
    },
  ) {}
}

export class DownloadGroupReportSuccess implements Action {
  readonly type = DOWNLOAD_GROUP_REPORT_SUCCESS;
}

export class DownloadGroupReportFailed implements Action {
  readonly type = DOWNLOAD_GROUP_REPORT_FAILED;
}

export class DownloadGroupReportRestart implements Action {
  readonly type = DOWNLOAD_GROUP_REPORT_RESTART;
}

export class GetHelp implements Action {
  readonly type = GET_HELP;

  constructor(public payload: HelpRequest) {}
}

export class GetVideoHelp implements Action {
  readonly type = GET_VIDEO_HELP;

  constructor(public payload: HelpRequest) {}
}

export class GetHelpCompleted implements Action {
  readonly type = GET_HELP_COMPLETED;

  constructor(
    public payload: { hints: Array<Hint>; TopicId: Guid; ExerciseId: Guid },
  ) {}
}

export class GetVideoHelpCompleted implements Action {
  readonly type = GET_VIDEO_HELP_COMPLETED;

  constructor(
    public payload: {
      video: Array<VideoHint>;
      TopicId: Guid;
      ExerciseId: Guid;
    },
  ) {}
}

export class StartExercise implements Action {
  readonly type = START_EXERCISE;

  constructor(public payload: ExerciseControl) {}
}

export class StartTeacherExercise implements Action {
  readonly type = START_TEACHER_EXERCISE;

  constructor(public payload: ExerciseControl) {}
}

export class StartExerciseCompleted implements Action {
  readonly type = START_EXERCISE_COMPLETED;

  constructor(
    public payload: { exercise: Exercise; TopicId: Guid; GroupId?: Guid },
  ) {}
}

export class StartExerciseFailed implements Action {
  readonly type = START_EXERCISE_FAILED;

  constructor(public payload: ExerciseControl) {}
}

export class StopExercise implements Action {
  readonly type = STOP_EXERCISE;

  constructor(public payload: ExerciseControl) {}
}

export class StopTeacherExercise implements Action {
  readonly type = STOP_TEACHER_EXERCISE;

  constructor(public payload: ExerciseControl) {}
}

export class StopExerciseCompleted implements Action {
  readonly type = STOP_EXERCISE_COMPLETED;

  constructor(
    public payload: { exercise: Exercise; TopicId: Guid; GroupId?: Guid },
  ) {}
}

export class StopExerciseFailed implements Action {
  readonly type = STOP_EXERCISE_FAILED;
}

export class SelectUnselectExercise implements Action {
  readonly type = SELECT_UNSELECT_EXERCISE;

  constructor(
    public payload: {
      selection: ExerciseSelection;
      selectionParam: ExerciseSelectionParams;
      displayModal?: boolean;
    },
  ) {}
}

export class SelectUnselectExerciseCompleted implements Action {
  readonly type = SELECT_UNSELECT_EXERCISE_COMPLETED;

  constructor(public payload: { exercise: Exercise; TopicId: Guid }) {}
}

export class SelectUnselectAll implements Action {
  readonly type = SELECT_UNSELECT_ALL;

  constructor(
    public payload: {
      selection: TopicSelection;
      selectionParamName: keyof ExerciseSelectionParams;
      selectionParam: ExerciseSelectionParams;
    },
  ) {}
}

export class SelectUnselectAllCompleted implements Action {
  readonly type = SELECT_UNSELECT_ALL_COMPLETED;

  constructor(
    public payload: {
      TopicId: Guid;
      exercises: Array<Exercise>;
      selectionParamName: keyof ExerciseSelectionParams;
    },
  ) {}
}

export class Answer implements Action {
  readonly type = ANSWER;

  constructor(public payload: AnswerPayload) {}
}

export class AnswerCompleted implements Action {
  readonly type = ANSWER_COMPLETED;

  constructor(
    public payload: { exercise: Exercise; TopicId: Guid; GroupId?: Guid },
  ) {}
}

export class AnswerCompletedWrong implements Action {
  readonly type = ANSWER_COMPLETED_WRONG;

  constructor(public payload: { exercise: { id: Guid } }) {}
}

export type CourseActions =
  | GetCourse
  | GetCourseByGroup
  | GetCourseCompleted
  | SetActiveGroup
  | SetCurrentTopic
  | GetCourseFailed
  | GetCourseStatistics
  | GetCourseStatisticsCompleted
  | GetTopicStatistics
  | GetTopicStatisticsCompleted
  | ExportCourseStatistics
  | ExportCourseStatisticsCompleted
  | ExportCourseStatisticsFailed
  | GetHelp
  | GetVideoHelp
  | GetHelpCompleted
  | GetVideoHelpCompleted
  | StartExercise
  | StartTeacherExercise
  | StartExerciseCompleted
  | StartExerciseFailed
  | StopExercise
  | StopTeacherExercise
  | StopExerciseCompleted
  | StopExerciseFailed
  | SelectUnselectExercise
  | SelectUnselectExerciseCompleted
  | SelectUnselectAll
  | SelectUnselectAllCompleted
  | Answer
  | AnswerCompleted
  | AnswerCompletedWrong
  | DownloadGroupReport
  | DownloadGroupReportSuccess
  | DownloadGroupReportFailed
  | DownloadIndividualReport
  | DownloadIndividualReportSuccess
  | DownloadIndividualReportFailed
  | DownloadGroupReportRestart;
