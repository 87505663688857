import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  distinctUntilChanged,
  map,
  switchMap,
  take,
  tap,
} from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { StudentRatingSystemFilterFormData } from "./models/student-rating-system-filter-form-data";
import {
  StudentRatingSystemFilterForm,
  StudentRatingSystemFilterGradeForm,
} from "./types/student-rating-system-filter-form";
import { descendingOrderValidator } from "./validators/descending-order.validator";
import { StudentRatingSystemFormService } from "./services/student-rating-system-form.service";
import { StudentRatingSystemGradeEntity } from "./interfaces/student-rating-system-grade-entity";
import { EMPTY, merge, Observable, of } from "rxjs";
import { isNil } from "lodash-es";
import { GradeOperator } from "../../../../shared/enums/grade-operator";
import { SelectButtonItem } from "src/app/shared/blocks/select-button/models/select-button-item";
import { SelectButtonOptionClickEvent } from "src/app/shared/blocks/select-button/events/select-button-option-click-event";
import {
  Config,
  CriteriaBarOutput,
  StudentPassCriteriaApi,
} from "src/app/shared/interfaces/student-pass-criteria-api";
import { PassCriteriaType } from "src/app/shared/enums/pass-criteria-type";
import { PassCriteriaService } from "src/app/shared/services/pass-criteria/pass-criteria.service";
import { Guid } from "src/app/shared/types/guid";
import { ActivatedRoute, Params } from "@angular/router";
import { StatElement } from "src/app/shared/interfaces/student";
import { Store } from "@ngrx/store";
import * as fromCourseStore from "src/app/store/reducers/course.reducer";
import { SelectItem } from "src/app/shared/blocks/dropdown/models/select-item";
import { SystemGradesRating } from "./enums/system-grades-rating.enum";
import { StatsService } from "src/app/shared/services/statistics/stats.service";
import { CRITERIA_TITLES_TEACHER } from "src/app/shared/consts/stats-criteria-titles";
import { statsCriteriaLoad } from "src/app/store/actions/stats/stats-criteria.actions";

@Component({
  selector: "student-rating-system-filter-form",
  templateUrl: "./student-rating-system-filter-form.component.html",
  styleUrls: ["./student-rating-system-filter-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentRatingSystemFilterFormComponent
  implements OnChanges, OnInit
{
  @Input() criteria: StudentPassCriteriaApi;
  @Input() criteriaConfigs: Config[] = [];
  @Output() saveEvent = new EventEmitter<StudentRatingSystemFilterFormData>();
  @Output() savePartialGradesEvent = new EventEmitter<StudentPassCriteriaApi>();
  @Output() saveCriteriaEvent = new EventEmitter<CriteriaBarOutput>();

  readonly recordTrackBy = (
    index: number,
    record: StudentRatingSystemGradeEntity,
  ): number => record.grade;

  readonly form: FormGroup<StudentRatingSystemFilterForm> = this.buildForm();
  records$: Observable<StudentRatingSystemGradeEntity[]>;
  readonly stats$: Observable<StatElement> = this.store.select(
    (state) => state.teacher.singleCourse.stats,
  );

  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  readonly ratingOptions$ = this.handleSetRatingOptions();
  readonly currentRule$ = this.stats.currentPartialGradeId;
  readonly currentView$ = this.stats.currentCriteriaView;
  readonly criteriaTitles = CRITERIA_TITLES_TEACHER;
  isCriteriaEditable = false;

  criteriaTypeEnum = PassCriteriaType;
  currentCriteriaType: PassCriteriaType = PassCriteriaType.GRADES;
  gradeTwoDisabled: boolean = false;
  isOn: boolean = true;
  individualGrades: boolean = false;
  mappedCriterias: CriteriaBarOutput[] = [];

  options: SelectItem<SystemGradesRating>[] = [
    {
      label: "1-6",
      value: SystemGradesRating["1-6"] + 1,
    },
    {
      label: "2-5",
      value: SystemGradesRating["2-5"] + 1,
    },
  ];

  gradeSystemControl = new FormControl(this.options[0].value);
  passThreshold = new FormControl(0, [Validators.min(0), Validators.max(100)]);

  CourseId: Guid;
  GroupId: Guid;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly ratingSystemService: StudentRatingSystemFormService,
    private readonly passCriteriaService: PassCriteriaService,
    private readonly route: ActivatedRoute,
    private readonly stats: StatsService,
    private readonly store: Store<{
      teacher: {
        singleCourse: fromCourseStore.CourseState;
      };
    }>,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.criteria?.currentValue?.criteria_type ===
        PassCriteriaType.GRADES &&
      this.criteria
    ) {
      this.currentCriteriaType = PassCriteriaType.GRADES;

      const hasGradeOne = this.criteria.grades?.[0]?.grade === 1;

      this.mapDataToCriterias();
      this.gradeSystemControl.setValue(this.options[hasGradeOne ? 0 : 1].value);
    } else {
      this.mappedCriterias = this.criteriaTitles.map((criteria) => ({
        name: criteria,
        exercises: [],
      }));
    }

    if (
      changes?.criteria?.currentValue?.criteria_type ===
      PassCriteriaType.PARTIAL_GRADES
    ) {
      this.currentCriteriaType = PassCriteriaType.PARTIAL_GRADES;
    }
  }

  ngOnInit(): void {
    this.records$ = this.ratingSystemService.fetchAll(
      this.criteria?.grades ?? [],
    );

    this.listenToHalfGradesVisibilityValueChanges();
    this.listenToRecordsChange();
    this.listenToCheckableControlsValueChanges();
    this.listenToMainPassControlValueChanges();
    this.getStatsData();

    if (this.criteria) {
      const hasGradeOne = this.criteria.grades?.[0]?.grade === 1;
      this.gradeTwoDisabled = !hasGradeOne;
    }
  }

  private getStatsData() {
    this.route.params.subscribe((params: Params) => {
      this.CourseId = params["CourseId"];
      this.GroupId = params["GroupId"];
    });
  }

  private listenToThirdControlValueChanges(): void {
    this.records$
      .pipe(
        switchMap(() => {
          const [first, second, third] = [
            ...this.form.controls.grades.controls,
          ].reverse();
          if (isNil(first) && isNil(second) && isNil(third)) {
            return EMPTY;
          }
          return of({ first, second, third });
        }),
        switchMap(({ first, second, third }) => {
          return third.valueChanges.pipe(
            tap((value) => {
              first.value.active
                ? second.patchValue({
                    prefix: GradeOperator.GREATER_OR_EQUAL,
                  })
                : second.patchValue({
                    prefix: GradeOperator.LESS_THAN,
                    range: value.range,
                  });
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  mapDataToCriterias(): void {
    const { config } = this.criteria;

    this.mappedCriterias = this.criteriaTitles.map((criteria, index) => ({
      name: criteria,
      exercises:
        index === 0
          ? config?.exercises?.map(({ id }) => id) || []
          : config?.required_exercises?.map(({ id }) => id) || [],
    }));
  }

  handleSaveClick(): void {
    if (this.form.invalid) {
      return;
    }

    this.ratingSystemService.update(this.toFormData());
    this.ratingSystemService.save();

    this.saveEvent.emit(this.toFormData());
  }

  handleRateEntireCourse(): void {
    this.passCriteriaService
      .calculatePartialGrades(this.CourseId, this.GroupId)
      .pipe(
        tap((response) => {
          if (response)
            this.store.dispatch(
              statsCriteriaLoad({
                CourseId: this.CourseId,
                GroupId: this.GroupId,
              }),
            );
        }),
      )
      .subscribe();
  }

  handleDisplayPartialGrades(): void {
    this.passCriteriaService
      .calculatePartialGrades(this.CourseId, this.GroupId)
      .pipe(
        switchMap((response) => {
          if (response)
            return this.passCriteriaService.getCriteriaForCourseGroup(
              this.CourseId,
              this.GroupId,
            );
        }),
        tap((response) => {
          if (response) {
            this.savePartialGradesEvent.emit(response.body);
            this.isOn = false;
          }
        }),
      )
      .subscribe();
  }

  handleEditConfigPartialGrades(): void {
    this.passCriteriaService
      .getCriteriaForCourseGroup(this.CourseId, this.GroupId)
      .subscribe((response) => {
        if (response) {
          this.savePartialGradesEvent.emit(response.body);
        }
      });
  }

  handleSetCriteriaConfigs(data: Config[]): void {
    this.criteriaConfigs = data;
  }

  gradeControl(
    record: StudentRatingSystemGradeEntity,
  ): StudentRatingSystemFilterGradeForm {
    const formArrayControl: FormArray<
      FormGroup<StudentRatingSystemFilterGradeForm>
    > = this.form.controls.grades;
    const found: FormGroup<StudentRatingSystemFilterGradeForm> =
      formArrayControl.controls.find(
        (control) => control.get("id").value === Number(record.label),
      );

    return found.controls;
  }

  private buildForm(): FormGroup<StudentRatingSystemFilterForm> {
    return this.formBuilder.group({
      halfGradesVisible: this.formBuilder.control(true),
      grades: this.formBuilder.array<
        FormGroup<StudentRatingSystemFilterGradeForm>
      >([], [descendingOrderValidator()]),
    });
  }

  private toFormData(): StudentRatingSystemFilterFormData {
    const { grades } = this.form.getRawValue();

    return new StudentRatingSystemFilterFormData(
      grades,
      this.form.value.halfGradesVisible,
      this.currentCriteriaType,
    );
  }

  private listenToMainPassControlValueChanges(): void {
    this.records$
      .pipe(
        distinctUntilChanged(),
        switchMap(() => {
          const activeControls = this.form.controls.grades.controls.filter(
            (control) => control.value.active == true,
          );
          const [first, second] = [...activeControls];

          if (isNil(first) && isNil(second)) {
            return EMPTY;
          }

          if (this.gradeSystemControl.value == this.options[0].value) {
            this.passThreshold.setValue(first.value.range, {
              emitEvent: false,
            });
          }
          if (this.gradeSystemControl.value == this.options[1].value) {
            this.passThreshold.setValue(second.value.range, {
              emitEvent: false,
            });
          }

          return of({ first, second });
        }),
        switchMap(({ first, second }) => {
          return this.passThreshold.valueChanges.pipe(
            tap((value) => {
              first.patchValue(
                { ...first.value, range: value },
                { emitEvent: false },
              );
              second.patchValue(
                { ...second.value, range: value },
                { emitEvent: false },
              );
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private listenToHalfGradesVisibilityValueChanges(): void {
    this.form.controls.halfGradesVisible.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        value
          ? this.listenToMaxMinValueChanges()
          : this.ratingSystemService.hideHalfGrades();

        this.ratingSystemService.update(this.toFormData());
      });
  }

  private listenToMaxMinValueChanges(): void {
    if (this.gradeSystemControl.value === this.options[0].value) {
      return this.ratingSystemService.showHalfGrades();
    } else {
      return this.ratingSystemService.showHalfGradesWithoutTwoAndFive();
    }
  }

  private listenToLastControlValueChanges(): void {
    this.records$
      .pipe(
        switchMap(() => {
          const [first, second] = [...this.form.controls.grades.controls];

          if (isNil(first) && isNil(second)) {
            return EMPTY;
          }

          this.passThreshold.setValue(first.value.range);
          return of({ first, second });
        }),
        switchMap(({ first, second }) => {
          return second.valueChanges.pipe(
            take(1),
            tap((value) => {
              value.active
                ? (first.patchValue({
                    prefix: GradeOperator.LESS_THAN,
                    range: value.range,
                  }),
                  this.passThreshold.setValue(value.range))
                : first.patchValue({
                    prefix: GradeOperator.GREATER_OR_EQUAL,
                  });
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private listenToCheckableControlsValueChanges(): void {
    this.records$
      .pipe(
        take(1),
        switchMap(() => {
          const controls = this.form.controls.grades.controls;

          return merge(
            ...controls.map((control: FormGroup) => {
              return control.valueChanges.pipe(
                map((value) => ({ value, control: control })),
                takeUntilDestroyed(this.destroyRef),
              );
            }),
          );
        }),
      )
      .subscribe(() =>
        setTimeout(() => this.ratingSystemService.update(this.toFormData())),
      );
  }

  private listenToRecordsChange(): void {
    this.records$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((records: StudentRatingSystemGradeEntity[]) => {
        this.form.controls.grades.clear();

        records.forEach((record: StudentRatingSystemGradeEntity, index) => {
          const control: FormGroup<StudentRatingSystemFilterGradeForm> =
            this.createControl(record);
          this.addControl(control);
        });

        this.form.controls.halfGradesVisible.setValue(
          records.some((record) => !Number.isInteger(record.grade)),
          { emitEvent: false, onlySelf: true },
        );
      });
  }

  onCriteriaData(criteria: CriteriaBarOutput): void {
    this.saveCriteriaEvent.emit(criteria);
  }

  private createControl(
    record: StudentRatingSystemGradeEntity,
  ): FormGroup<StudentRatingSystemFilterGradeForm> {
    return this.formBuilder.group({
      id: this.formBuilder.control(record.grade),
      active: this.formBuilder.control(record.isActive),
      range: this.formBuilder.control(record.value, [
        Validators.min(0),
        Validators.max(100),
        Validators.required,
        Validators.pattern("^[0-9]*$"),
      ]),
      prefix: this.formBuilder.control(record.prefix),
    });
  }

  private addControl(
    control: FormGroup<StudentRatingSystemFilterGradeForm>,
  ): void {
    this.form.controls.grades.push(control);
  }

  handleDisplayProperlyRatings(option: SelectItem<SystemGradesRating>): void {
    this.form.controls.halfGradesVisible.setValue(false);
    const [first, second] = this.form.controls.grades.controls;
    const [last] = [...this.form.controls.grades.controls].reverse();

    if (option.value === SystemGradesRating["1-6"] + 1) {
      first.patchValue({ active: true });
      last.patchValue({ active: true });
      second.patchValue({ prefix: GradeOperator.GREATER_OR_EQUAL });

      this.gradeTwoDisabled = false;
      this.ratingSystemService.showMaxMinGrades();
    } else if (
      option.value === SystemGradesRating["2-5"] + 1 &&
      first.value.id === 1
    ) {
      first.patchValue({ active: false });
      last.patchValue({ active: false });
      second.patchValue({ prefix: GradeOperator.LESS_THAN });

      this.gradeTwoDisabled = true;
      this.ratingSystemService.hideMaxMinGrades();
    }

    this.ratingSystemService.update(this.toFormData());
  }

  onEditableChanged(isEditable: boolean, index: number): void {
    if (isEditable) {
      this.stats.setCurrentPartialGrade(this.criteriaTitles[index]);
      this.isCriteriaEditable = true;
    } else {
      this.stats.setCurrentPartialGrade(null);
      this.isCriteriaEditable = false;
    }
  }

  private handleSetRatingOptions(): Observable<
    SelectButtonItem<PassCriteriaType>[]
  > {
    return of([
      new SelectButtonItem(
        "COURSES.COURSE.RATE_ALL_COURSE",
        PassCriteriaType.GRADES,
      ),
      new SelectButtonItem(
        "COURSES.COURSE.RATE_PARTIAL_COURSE",
        PassCriteriaType.PARTIAL_GRADES,
      ),
    ]);
  }

  handleShowRateOption(
    rate: SelectButtonOptionClickEvent<PassCriteriaType>,
  ): void {
    this.currentCriteriaType = rate.option.value;
    if (rate.option.value) {
      this.individualGrades = true;
    } else {
      this.individualGrades = false;
    }
  }

  toggle(): void {
    this.isOn = !this.isOn;
  }

  getClass(index: number, records: StudentRatingSystemGradeEntity[]): string {
    const activeRecords = records.filter((record) => record.isActive);
    const activeIndex = activeRecords.findIndex((r) => r === records[index]); // Znajduje nowy indeks w aktywnej liście

    if (activeIndex === -1) return "";

    return `grade${activeIndex + 1}-total${activeRecords.length}`;
  }

  firstAvailableRecord(
    records: StudentRatingSystemGradeEntity[],
    record: StudentRatingSystemGradeEntity,
  ): boolean {
    const activeRecords = records
      .filter((record) => record.isActive)
      .slice(0, 2);

    return activeRecords.includes(record);
  }
}
