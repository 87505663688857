<modal-overlay>
  <modal-box>
    <key-icon
      size="md"
      theme="light"
      kind="warning"
      icon="map-marker-question"
    />
    <div class="flag-exercise-modal mt-4">
      <div class="flag-exercise-modal-content">
        <h5 class="flag-exercise-modal-title">{{ modalTitle | translate }}</h5>
        <p class="flag-exercise-modal-desc">{{ data.message | translate }}</p>

        <textarea
          class="flag-exercise-modal-textarea"
          [formControl]="message"
          [placeholder]="
            'COURSES.COURSE.EXERCISE.FLAGGED_EXERCISE_MODAL.PLACEHOLDER'
              | translate
          "
        ></textarea>
        <div
          *ngIf="message.invalid && (message.dirty || message.touched)"
          class="d-flex flex-column mini-label color-hinvalid validation-text font-weight-bold pt-1"
        >
          <span *ngIf="message.errors?.required">
            {{
              "COURSES.COURSE.EXERCISE.FLAGGED_EXERCISE_MODAL.VALIDATE_MESSAGE_REQUIRED"
                | translate
                | uppercase
            }}
          </span>
          <span *ngIf="message.errors?.maxlength">
            {{
              "COURSES.COURSE.EXERCISE.FLAGGED_EXERCISE_MODAL.VALIDATE_MESSAGE_MAXLENGTH"
                | translate
                  : { maxLength: message.errors?.maxlength?.requiredLength }
                | uppercase
            }}
          </span>
        </div>

        <div class="flag-exercise-modal-buttons w-100 mt-4">
          <button
            type="button"
            class="btn-blue-light-600 btn-lg w-50"
            (click)="cancel()"
          >
            {{ "GLOBAL.CANCEL_BUTTON" | translate }}
          </button>

          <button
            type="button"
            class="btn-primary-900 btn-lg w-50"
            [disabled]="message.invalid"
            (click)="confirm()"
          >
            {{ "QUIZ.EXERCISE.SEND" | translate }}
          </button>
        </div>
      </div>
    </div>
  </modal-box>
</modal-overlay>
