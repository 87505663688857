import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ModalComponent } from "../../modal/modal.component";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

const DEFAULT_QUIZ_TIME = 60;

export interface QuizInfoStudentForm {
  time_limit: FormControl<string>;
  max_attempts: FormControl<number | string>;
}

@Component({
  selector: "quiz-info-student-modal",
  templateUrl: "./quiz-info-student-modal.component.html",
  styleUrls: ["./quiz-info-student-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuizInfoStudentModalComponent
  extends ModalComponent<{ timeLimit: number; maxAttempts: number }>
  implements OnInit
{
  form: FormGroup<QuizInfoStudentForm> = this.initForm();
  defaultTime = DEFAULT_QUIZ_TIME;

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.initFormValues();
  }

  initForm(): FormGroup<QuizInfoStudentForm> {
    return this.fb.group<QuizInfoStudentForm>({
      time_limit: this.fb.control({
        value: null,
        disabled: true,
      }),
      max_attempts: this.fb.control({
        value: 0,
        disabled: true,
      }),
    });
  }

  initFormValues(): void {
    this.form.controls.time_limit.setValue(
      `${this.data.timeLimit ? this.data.timeLimit : this.defaultTime} min`,
    );
    this.form.controls.max_attempts.setValue(
      this.data.maxAttempts ? this.data.maxAttempts : "-",
    );
  }

  startQuiz(): void {
    this.close.emit("confirm");
    this.closeModal();
  }

  closeModal(): void {
    this.close.emit("close");
  }
}
