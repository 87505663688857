import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  CriteriaBarPayload,
  StudentPassCriteriaApi,
} from "../../interfaces/student-pass-criteria-api";
import { Guid } from "../../types/guid";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class PassCriteriaService {
  constructor(private apiService: ApiService) {}

  getCriteriaForCourseGroup(
    courseId: Guid,
    groupId: Guid,
  ): Observable<HttpResponse<StudentPassCriteriaApi>> {
    return this.apiService.getRequest(
      `courses/${courseId}/groups/${groupId}/pass_criteria/`,
      { observe: "response" },
    );
  }

  saveCriteriaForCourseGroup(
    courseId: Guid,
    groupId: Guid,
    criteria: StudentPassCriteriaApi,
  ): Observable<StudentPassCriteriaApi> {
    return this.apiService.putRequest(
      `courses/${courseId}/groups/${groupId}/pass_criteria/update/`,
      criteria,
    );
  }

  savePartialGrade(
    courseId: Guid,
    groupId: Guid,
    partialGrade: CriteriaBarPayload,
  ): Observable<unknown> {
    return this.apiService.postRequest(
      `courses/${courseId}/groups/${groupId}/pass_criteria/update_config/`,
      partialGrade,
    );
  }

  editPartialGrade(
    courseId: Guid,
    groupId: Guid,
    partialGrade: CriteriaBarPayload,
    garlicId: string,
  ): Observable<unknown> {
    return this.apiService.postRequest(
      `courses/${courseId}/groups/${groupId}/pass_criteria/update_config/${garlicId}/`,
      partialGrade,
    );
  }

  deletePartialGrade(
    courseId: Guid,
    groupId: Guid,
    garlicId: string,
  ): Observable<void> {
    return this.apiService.deleteRequest(
      `courses/${courseId}/groups/${groupId}/pass_criteria/delete_config/${garlicId}/`,
    );
  }

  calculatePartialGrades(courseId: Guid, groupId: Guid): Observable<unknown> {
    return this.apiService.postRequest(
      `courses/${courseId}/groups/${groupId}/pass_criteria/update_grades/`,
      {},
    );
  }
}
