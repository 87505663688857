import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { StudentPassCriteriaApi } from "src/app/shared/interfaces/student-pass-criteria-api";

export const prefix = "[Stats Criteria All Edit]";

const statsCriteriaAllEditActions = {
  request: `${prefix} Load`,
  requestActiveSuccess: `${prefix} Load success`,
  requestFail: `${prefix} Load fail`,
};

export const statsCriteriaAllEditLoad = createAction(
  statsCriteriaAllEditActions.request,
  props<{
    CourseId: string;
    GroupId: string;
    criteria: StudentPassCriteriaApi;
  }>(),
);

export const statsCriteriaEditAllLoadSuccess = createAction(
  statsCriteriaAllEditActions.requestActiveSuccess,
  props<{ criteria: StudentPassCriteriaApi | null }>(),
);

export const statsCriteriaEditAllLoadFailure = createAction(
  statsCriteriaAllEditActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
