import { ChangeDetectorRef, Injectable, inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StatsService {
  private currentPartialGrade = new BehaviorSubject<string | null>(null);
  private selectable = new BehaviorSubject<boolean>(false);
  private selectedItems = new BehaviorSubject<string[]>([]);
  private highlightableItems = new BehaviorSubject<boolean>(false);
  private criteriaView = new BehaviorSubject<string | null>(null);

  currentPartialGradeId = this.currentPartialGrade.asObservable();
  currentItems = this.selectedItems.asObservable();
  selectableFlag = this.selectable.asObservable();
  highlightableExercises = this.highlightableItems.asObservable();
  currentCriteriaView = this.criteriaView.asObservable();

  setCurrentPartialGrade(id: string | null): void {
    this.currentPartialGrade.next(id);
  }

  addItem(item: string): void {
    const currentItems = this.selectedItems.getValue();

    if (!currentItems.includes(item)) {
      this.selectedItems.next([...currentItems, item]);
    }
  }

  removeItem(item: string): void {
    const currentItems = this.selectedItems.getValue();
    const updatedItems = currentItems.filter((e) => e !== item);
    this.selectedItems.next(updatedItems);
  }

  toggleItem(item: string): void {
    const currentItems = this.selectedItems.getValue();
    const index = currentItems.findIndex((e) => e === item);

    if (index !== -1) {
      this.removeItem(item);
    } else {
      this.addItem(item);
    }
  }

  clearExercises(): void {
    this.selectedItems.next([]);
  }

  changeSelectable(value: boolean): void {
    this.selectable.next(value);
  }

  changeHighlightable(value: boolean): void {
    this.highlightableItems.next(value);
  }

  changeCriteriaView(view: string): void {
    this.criteriaView.next(view);
  }
}
