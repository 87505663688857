<button
  class="course-exercise-answer-menu"
  [tooltip]="'GLOBAL.OPEN_THE_LIST_IN_DIALOG_BOX' | translate"
  (click)="handleShowAnswerListModal()"
>
  <div class="d-flex">
    <icon name="group" />

    <span class="ml-3">{{ count }}</span>
  </div>

  <span class="course-exercise-answer-menu-label">
    {{ "COURSES.COURSE.EXERCISE.ANSWER_LIST" | translate }}
  </span>

  <icon name="arrow-forward-outline" />
</button>
