import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Observable } from "rxjs";
import {
  FlaggedExercise,
  FlaggedExerciseList,
} from "../../interfaces/flagged-exercises";

@Injectable({
  providedIn: "root",
})
export class FlaggedExercisesService {
  constructor(private readonly apiService: ApiService) {}

  createHelpRequest(
    courseId: string,
    groupId: string,
    exerciseId: string,
    message: string,
  ): Observable<FlaggedExercise> {
    return this.apiService.postRequest<FlaggedExercise>(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/need_help/`,
      { comment: message },
    );
  }

  cancelHelpRequest(
    courseId: string,
    groupId: string,
    exerciseId: string,
    flagId: string,
  ): Observable<FlaggedExercise> {
    return this.apiService.putRequest<FlaggedExercise>(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/need_help/${flagId}/`,
      {},
    );
  }

  getHelpRequests(
    courseId: string,
    groupId: string,
  ): Observable<FlaggedExerciseList[]> {
    return this.apiService.getRequest<FlaggedExerciseList[]>(
      `courses/${courseId}/groups/${groupId}/help_requests`,
    );
  }

  resolveHelpRequest(
    courseId: string,
    groupId: string,
    flagId: string,
  ): Observable<FlaggedExerciseList> {
    return this.apiService.putRequest<FlaggedExerciseList>(
      `courses/${courseId}/groups/${groupId}/help_requests/${flagId}/`,
      {},
    );
  }

  getHelpRequestsHistory(
    courseId: string,
    groupId: string,
  ): Observable<FlaggedExercise[]> {
    return this.apiService.getRequest<FlaggedExercise[]>(
      `courses/${courseId}/groups/${groupId}/students/help_requests/`,
    );
  }
}
