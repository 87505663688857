import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { StatsService } from "src/app/shared/services/statistics/stats.service";
import { ActivatedRoute, Params } from "@angular/router";
import { Guid } from "src/app/shared/types/guid";
import { PassCriteriaService } from "src/app/shared/services/pass-criteria/pass-criteria.service";
import {
  Config,
  CriteriaBarPayload,
} from "src/app/shared/interfaces/student-pass-criteria-api";
import { HttpStatusCode } from "@angular/common/http";

@Component({
  selector: "student-individual-grades",
  templateUrl: "./student-individual-grades.component.html",
  styleUrls: ["./student-individual-grades.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentIndividualGradesComponent implements OnInit {
  @Input() individualMarks: Config[];
  @Input() disabled: boolean;
  @Output() savePartialGradesEvent = new EventEmitter<void>();
  @Output() updatePartialGradesEvent = new EventEmitter<Config[]>();

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly passCriteriaService: PassCriteriaService,
    private readonly route: ActivatedRoute,
    private readonly stats: StatsService,
  ) {}

  CourseId: Guid;
  GroupId: Guid;
  displayGradeCreator: boolean = false;
  currentEditingId: string | null = null;

  ngOnInit(): void {
    this.getStatsData();
  }

  getStatsData() {
    this.route.params.subscribe((params: Params) => {
      this.CourseId = params["CourseId"];
      this.GroupId = params["GroupId"];
    });
  }

  handleEditGrade(gradeId: string) {
    this.currentEditingId = gradeId;
  }

  handleSetGradeCreator(value: boolean): void {
    if (value) {
      this.stats.changeSelectable(true);
      this.stats.clearExercises();
    }

    this.currentEditingId = null;
    this.displayGradeCreator = value;
    this.changeDetector.detectChanges();
  }

  setSelectedExercises(exercises: string[]): void {
    if (exercises.length === 0) {
      this.deletePartialGrade();
    } else {
      this.handleSetGradeCreator(false);
    }
  }

  handleCreatePartialGrade(partialGrade: {
    form: CriteriaBarPayload;
    id: string;
  }): void {
    if (partialGrade.id) {
      this.updatePartialGrade(partialGrade.form, partialGrade.id);
    } else {
      this.saveNewPartialGrade(partialGrade.form);
    }
    this.currentEditingId = null;
    this.changeDetector.detectChanges();
  }

  private updatePartialGrade(
    partialGrade: CriteriaBarPayload,
    id: string,
  ): void {
    this.passCriteriaService
      .editPartialGrade(this.CourseId, this.GroupId, partialGrade, id)
      .subscribe((response: Config) => {
        this.handleResponse(response, "UPDATE");
      });
  }

  private saveNewPartialGrade(partialGrade: CriteriaBarPayload): void {
    this.passCriteriaService
      .savePartialGrade(this.CourseId, this.GroupId, partialGrade)
      .subscribe((response: Config) => this.handleResponse(response, "SAVE"));
    this.changeDetector.detectChanges();
  }

  deletePartialGrade(): void {
    const currentId = this.currentEditingId;
    this.currentEditingId = null;

    this.passCriteriaService
      .deletePartialGrade(this.CourseId, this.GroupId, currentId)
      .subscribe(() => this.refreshCriteria("DELETE"));
  }

  private handleResponse(response: Config, type: "SAVE" | "UPDATE"): void {
    if (response) {
      this.refreshCriteria(type);
    }
  }

  private refreshCriteria(type: "SAVE" | "UPDATE" | "DELETE"): void {
    this.passCriteriaService
      .getCriteriaForCourseGroup(this.CourseId, this.GroupId)
      .subscribe((response) => this.processCriteriaResponse(response, type));
  }

  private processCriteriaResponse(
    response: any,
    type: "SAVE" | "UPDATE" | "DELETE",
  ): void {
    switch (response.status) {
      case HttpStatusCode.NoContent:
        break;

      case HttpStatusCode.Ok:
        this.individualMarks = response.body.configs;
        if (type === "UPDATE" || type === "DELETE")
          this.savePartialGradesEvent.emit();
        if (type === "SAVE")
          this.updatePartialGradesEvent.emit(this.individualMarks);

        this.changeDetector.detectChanges();
        break;

      default:
        break;
    }
  }
}
