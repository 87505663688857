import { FormArray, ValidatorFn } from "@angular/forms";
import {
  appendAbstractControlError,
  markAsTouchedAndMarkAsDirty,
  removeAbstractControlError,
} from "../../../../../shared/utils/abstract-control-error";
import { isNil } from "lodash-es";

export const descendingOrderValidator = (): ValidatorFn => {
  return (formArray: FormArray): { [key: string]: unknown } | null => {
    const controls = formArray.controls.filter(
      (control) => control.get("active").value,
    );

    const [first, second] = [...controls];

    if (!isNil(first) && !isNil(second)) {
      const firstRef = first.get("range");
      const secondRef = second.get("range");

      if (firstRef.value !== secondRef.value) {
        appendAbstractControlError(firstRef, { invalidOrder: true });
        appendAbstractControlError(secondRef, { invalidOrder: true });

        markAsTouchedAndMarkAsDirty(firstRef);
        markAsTouchedAndMarkAsDirty(secondRef);

        return;
      }
    }

    const transformed = controls.map((control) => ({
      control: control.get("range"),
      range: control.get("range").value,
    }));

    if (transformed.length <= 1) {
      return null;
    }

    transformed.forEach(({ control }) => {
      removeAbstractControlError(control, "invalidOrder");
      markAsTouchedAndMarkAsDirty(control);
    });

    for (let i = 0; i < transformed.length - 1; i++) {
      if (i === 0) {
        continue;
      }

      if (transformed[i].range >= transformed[i + 1].range) {
        appendAbstractControlError(transformed[i].control, {
          invalidOrder: true,
        });
        appendAbstractControlError(transformed[i + 1].control, {
          invalidOrder: true,
        });
        markAsTouchedAndMarkAsDirty(transformed[i].control);
        markAsTouchedAndMarkAsDirty(transformed[i + 1].control);
        return;
      }
    }

    return null;
  };
};
