<form [formGroup]="form">
  <div *ngIf="stats$ | async as stats" class="form-container">
    <span class="form-container__rating-system-title">
      {{ "COURSES.COURSE.RATING_SYSTEM_LABEL" | translate }}
    </span>
    <div class="pt-2">
      <select-button
        [options]="ratingOptions$ | async"
        [selected]="currentCriteriaType"
        (optionClickEvent)="handleShowRateOption($event)"
      />
    </div>

    <div class="pt-5 pb-5">
      <p class="form-container__rating-requirements-title pb-3">
        {{ "COURSES.COURSE.RATING_REQUIREMENTS_LABEL" | translate }}
      </p>

      <div
        *ngIf="records$ | async as records"
        class="row justify-content-center"
      >
        <dropdown
          class="col-sm-8"
          [placeholder]="
            'COURSES.COURSE.RATING_REQUIREMENTS_PLACEHOLDER' | translate
          "
          [options]="options"
          [formControl]="gradeSystemControl"
          [textCentered]="true"
          (selectedOptionsEmitter)="handleDisplayProperlyRatings($event)"
        />

        <input-number
          class="form-grade-group-input col-sm-4"
          mask="999"
          suffix="%"
          prefix="&ge;"
          [formControl]="passThreshold"
        />
      </div>
    </div>
    <div class="form-container__header" (click)="toggle()">
      <p class="form-container__title">
        {{ "COURSES.COURSE.SET_GRADES" | translate }}
      </p>
      <span
        class="typcn typcn-chevron-right chevron-toggled"
        [ngClass]="{
          toggled: isOn
        }"
      ></span>
    </div>

    <div
      *ngIf="isOn"
      class="form-container__content d-flex flex-column justify-content-center pt-4"
    >
      <span class="form-container__rating-requirements-title">
        {{ "COURSES.COURSE.RATING_SYSTEM_HINT" | translate }}
      </span>
      <div class="pt-5">
        <div class="d-flex justify-content-start mb-4">
          <input-switch
            [label]="'COURSES.COURSE.HALF_MARKS' | translate | lowercase"
            [formControl]="form.controls.halfGradesVisible"
          />
        </div>
        <ng-container *ngIf="records$ | async as records">
          <div class="records-grid pr-5 pl-5">
            <ng-container
              *ngFor="
                let record of records;
                let i = index;
                let first = first;
                trackBy: recordTrackBy
              "
            >
              <ng-container *ngIf="!!gradeControl(record).active.value">
                <div class="form-grade-group" [ngClass]="getClass(i, records)">
                  <div class="d-inline-flex align-items-center">
                    <div class="form-grade-group-label">
                      {{ record.label }}
                    </div>

                    <div class="form-grade-group-input-container">
                      <ng-container *ngIf="!!gradeControl(record).active.value">
                        <input-number
                          class="form-grade-group-input"
                          mask="999"
                          suffix="%"
                          [prefix]="
                            gradeControl(record).prefix.value | operatorLiteral
                          "
                          [formControl]="gradeControl(record).range"
                          [readOnly]="firstAvailableRecord(records, record)"
                        />
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <button
          type="button"
          class="button theme-hprimarydark px-4"
          [disabled]="form.invalid"
          (click)="handleSaveClick()"
        >
          {{ "GLOBAL.SAVE" | translate }}
        </button>
      </div>
    </div>

    <div
      *ngIf="currentCriteriaType !== criteriaTypeEnum.PARTIAL_GRADES"
      [ngClass]="{
        disabled:
          !criteria || criteria.criteria_type !== criteriaTypeEnum.GRADES
      }"
    >
      <div
        *ngFor="let mapCriteria of mappedCriterias; let i = index"
        class="criteria mt-5"
      >
        <criteria-bar
          [criteria]="mapCriteria"
          [isEditable]="(currentRule$ | async) === mapCriteria"
          [isDisabled]="
            isCriteriaEditable && !((currentRule$ | async) === mapCriteria.name)
          "
          (editableChanged)="onEditableChanged($event, i)"
          (criteriaValues)="onCriteriaData($event)"
        />
      </div>

      <div class="d-flex justify-content-center pt-4">
        <button
          type="button"
          class="button btn-primary-900"
          [disabled]="
            !criteria || criteria.criteria_type !== criteriaTypeEnum.GRADES
          "
          (click)="handleRateEntireCourse()"
        >
          {{ "COURSES.COURSE.RATE_COURSE" | translate }}
        </button>
      </div>
    </div>

    <div
      *ngIf="currentCriteriaType === criteriaTypeEnum.PARTIAL_GRADES"
      class="pt-4"
    >
      <student-individual-grades
        [individualMarks]="criteriaConfigs"
        [disabled]="
          !criteria ||
          criteria.criteria_type !== criteriaTypeEnum.PARTIAL_GRADES
        "
        (savePartialGradesEvent)="handleEditConfigPartialGrades()"
        (updatePartialGradesEvent)="handleSetCriteriaConfigs($event)"
      />
      <div class="d-flex justify-content-center pt-4">
        <button
          type="button"
          class="button btn-primary-900"
          [disabled]="
            !criteria ||
            criteria.criteria_type !== criteriaTypeEnum.PARTIAL_GRADES ||
            !stats.stats_with_user
          "
          (click)="handleSaveClick(); handleDisplayPartialGrades()"
        >
          {{ "COURSES.COURSE.RATE_COURSE" | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
