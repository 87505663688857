import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { KeyIconKind, KeyIconSize, KeyIconTheme } from "../../types/key-icon";
import { IconSet } from "../../types/icon-set";
import { IconName } from "../../types/icon-name";

@Component({
  selector: "key-icon",
  templateUrl: "./key-icon.component.html",
  styleUrls: ["./key-icon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyIconComponent {
  @Input() kind: KeyIconKind = "success";
  @Input() size: KeyIconSize = "lg";
  @Input() theme: KeyIconTheme = "dark-outline";
  @Input() set: IconSet = "uil";
  @Input() icon: IconName = "check";
}
