<modal-overlay>
  <modal-box>
    <div class="warning-modal-body row no-gutters">
      <div class="col col-auto">
        <span class="warning-icon typcn typcn-{{ data.icon }}"></span>
      </div>
      <div class="col modal-content">
        <h5 class="modal-title">{{ modalTitle | translate }}</h5>
        <p
          class="modal-text"
          [innerHTML]="data.text | translate | replaceNewLine"
        ></p>
        <div class="d-flex align-items-center modal-buttons">
          <button
            type="button"
            class="modal-button btn-primary-900"
            (click)="dismiss()"
          >
            {{ data.buttonText | translate | uppercase }}
          </button>
        </div>
      </div>
    </div>
  </modal-box>
</modal-overlay>
