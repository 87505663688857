<div class="d-flex justify-content-center align-items-center">
  <button
    class="round-btn round-btn__accept d-flex justify-content-center align-items-center mr-4"
    tooltip="GROUPS.EDIT.EDIT_GROUP_BUTTON"
    [disabled]="disabled"
    [ngClass]="{
      'round-btn__disabled': disabled
    }"
    (click)="save()"
  >
    <icon
      class="round-btn__accept-icon"
      [ngClass]="{
        'round-btn__disabled-icon': disabled
      }"
      set="uil"
      name="check"
    ></icon>
  </button>
  <button
    class="round-btn round-btn__cancel d-flex justify-content-center align-items-center"
    tooltip="GLOBAL.CANCEL_BUTTON"
    (click)="cancel()"
  >
    <icon class="round-btn__cancel-icon" name="cancel-outline"></icon>
  </button>
</div>
