import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { FlaggedExercisesService } from "src/app/shared/services/flagged-exercises/flagged-exercises.service";
import {
  flaggedExerciseFailure,
  flaggedExerciseLoad,
  flaggedExerciseLoadSuccess,
} from "../../actions/flagged-exercises/create-flagged-exercise.actions";
import {
  FlaggedExercise,
  FlaggedExerciseList,
} from "src/app/shared/interfaces/flagged-exercises";
import {
  flaggedExerciseListFailure,
  flaggedExerciseListLoad,
  flaggedExerciseListLoadSuccess,
} from "../../actions/flagged-exercises/get-flagged-exercises.actions";
import {
  cancelflaggedExerciseFailure,
  cancelflaggedExerciseLoad,
  cancelflaggedExerciseLoadSuccess,
} from "../../actions/flagged-exercises/cancel-flagged-exercise.actions";
import {
  resolveflaggedExerciseLoad,
  resolveflaggedExerciseLoadSuccess,
  resolveflaggedExerciseFailure,
} from "../../actions/flagged-exercises/resolve-flagged-exercise.actions";
import {
  flaggedExerciseHistoryListLoad,
  flaggedExerciseHistoryListLoadSuccess,
  flaggedExerciseHistoryListFailure,
} from "../../actions/flagged-exercises/get-flagged-history-exercises.actions";

@Injectable()
export class FlaggedExerciseEffect {
  createFlaggedExercise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flaggedExerciseLoad),
      switchMap((param) => {
        return this.flaggedExercise
          .createHelpRequest(
            param.courseId,
            param.groupId,
            param.exerciseId,
            param.message,
          )
          .pipe(
            map((res: FlaggedExercise) =>
              flaggedExerciseLoadSuccess({ exercise: res }),
            ),
            catchError(({ error }) => of(flaggedExerciseFailure(error))),
          );
      }),
    ),
  );

  getFlaggedExerciseList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flaggedExerciseListLoad),
      switchMap((param) => {
        return this.flaggedExercise
          .getHelpRequests(param.courseId, param.groupId)
          .pipe(
            map((res: FlaggedExerciseList[]) =>
              flaggedExerciseListLoadSuccess({ exercises: res }),
            ),
            catchError(({ error }) => of(flaggedExerciseListFailure(error))),
          );
      }),
    ),
  );

  cancelFlaggedExercise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelflaggedExerciseLoad),
      switchMap((param) => {
        return this.flaggedExercise
          .cancelHelpRequest(
            param.courseId,
            param.groupId,
            param.exerciseId,
            param.flagId,
          )
          .pipe(
            map((res: FlaggedExercise) =>
              cancelflaggedExerciseLoadSuccess({ exercise: res }),
            ),
            catchError(({ error }) => of(cancelflaggedExerciseFailure(error))),
          );
      }),
    ),
  );

  resolveFlaggedExercise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resolveflaggedExerciseLoad),
      switchMap((param) => {
        return this.flaggedExercise
          .resolveHelpRequest(param.courseId, param.groupId, param.flagId)
          .pipe(
            map((res: FlaggedExerciseList) =>
              resolveflaggedExerciseLoadSuccess({
                exercise: res,
                courseId: param.courseId,
                groupId: param.groupId,
              }),
            ),
            catchError(({ error }) => of(resolveflaggedExerciseFailure(error))),
          );
      }),
    ),
  );

  refreshFlaggedExerciseListAfterResolve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resolveflaggedExerciseLoadSuccess),
      map((action) => {
        return flaggedExerciseListLoad({
          courseId: action.courseId,
          groupId: action.groupId,
        });
      }),
    ),
  );

  getFlaggedExerciseHistoryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flaggedExerciseHistoryListLoad),
      switchMap((param) => {
        return this.flaggedExercise
          .getHelpRequestsHistory(param.courseId, param.groupId)
          .pipe(
            map((res: FlaggedExercise[]) =>
              flaggedExerciseHistoryListLoadSuccess({ exercises: res }),
            ),
            catchError(({ error }) =>
              of(flaggedExerciseHistoryListFailure(error)),
            ),
          );
      }),
    ),
  );

  constructor(
    private flaggedExercise: FlaggedExercisesService,
    private actions$: Actions,
  ) {}
}
