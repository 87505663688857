import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { isNil } from "lodash-es";
import { StudentRatingSystemFormService } from "src/app/modules/teacher/components/student-rating-system-filter-form/services/student-rating-system-form.service";
import { Nillable } from "src/app/shared/types/nillable";
import { StudentStatElement } from "src/app/shared/interfaces/student";
import { StudentRatingSystemGradeEntity } from "src/app/modules/teacher/components/student-rating-system-filter-form/interfaces/student-rating-system-grade-entity";

@Pipe({
  name: "studentExerciseGradeCellClass",
})
export class StudentExerciseGradeCellClassPipe implements PipeTransform {
  constructor(
    private readonly ratingSystemService: StudentRatingSystemFormService,
  ) {}

  transform(
    statistic: StudentStatElement,
    grades: StudentRatingSystemGradeEntity[],
  ): Observable<string> {
    return this.ratingSystemService
      .calculateGradeByPercentage(
        grades,
        this.ratingSystemService.calculateTotalPercentage(
          this.ratingSystemService.calculateTotalPoints(statistic),
          this.ratingSystemService.calculateMaxPoints(statistic),
        ),
      )
      .pipe(
        map((grade: Nillable<number>) => {
          if (isNil(grade)) {
            return null;
          }

          if (grades[0].grade == 1) {
            return grade < 2 ? "failed" : "passed";
          }

          return grade < 3 ? "failed" : "passed";
        }),
      );
  }
}
