import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Nillable } from "../../../../shared/types/nillable";
import { Observable, of } from "rxjs";
import { SelectItem } from "../../../../shared/blocks/dropdown/models/select-item";
import { StudentPassFilterForm } from "./types/student-pass-filter-form";
import { StudentPassFilterFormData } from "./types/models/student-pass-filter-form-data";
import { StudentPassConditionFormService } from "./types/services/student-pass-condition-form.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { isNil } from "lodash-es";
import { StatsService } from "src/app/shared/services/statistics/stats.service";
import {
  CriteriaBarOutput,
  StudentPassCriteriaApi,
} from "src/app/shared/interfaces/student-pass-criteria-api";
import { PassCriteriaType } from "src/app/shared/enums/pass-criteria-type";
import { CRITERIA_TITLES_TEACHER } from "src/app/shared/consts/stats-criteria-titles";

@Component({
  selector: "student-pass-filter-form",
  templateUrl: "./student-pass-filter-form.component.html",
  styleUrls: ["./student-pass-filter-form.component.scss"],
})
export class StudentPassFilterFormComponent implements OnInit, OnChanges {
  @Input() criteria: StudentPassCriteriaApi;
  @Output() saveEvent = new EventEmitter<StudentPassFilterFormData>();
  @Output() saveCriteriaEvent = new EventEmitter<CriteriaBarOutput>();
  @Output() calculateGradesEvent = new EventEmitter<void>();

  suffix = "%";
  isCriteriaEditable = false;

  readonly form = this.buildForm();
  readonly passSelectItems$ = this.passSelectItems();
  readonly currentRule$ = this.stats.currentPartialGradeId;
  readonly criteriaTitles = CRITERIA_TITLES_TEACHER;
  readonly PassCriteriaType = PassCriteriaType;

  mappedCriterias: CriteriaBarOutput[] = [];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly studentPassCondition: StudentPassConditionFormService,
    private readonly destroyRef: DestroyRef,
    private readonly stats: StatsService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.criteria?.currentValue?.criteria_type ===
        PassCriteriaType.THRESHOLD &&
      this.criteria
    ) {
      this.mapDataToCriterias();
    } else {
      this.mappedCriterias = this.criteriaTitles.map((criteria) => ({
        name: criteria,
        exercises: [],
      }));
    }
  }

  ngOnInit(): void {
    this.listenToDataChange();
  }

  handleSaveClick(): void {
    if (this.form.invalid) {
      return;
    }

    this.studentPassCondition.save(this.toFormData());
    this.saveEvent.emit(this.toFormData());
  }

  handleCalculateGrades(): void {
    this.calculateGradesEvent.emit();
  }

  mapDataToCriterias(): void {
    const { config } = this.criteria;

    this.mappedCriterias = this.criteriaTitles.map((criteria, index) => ({
      name: criteria,
      exercises:
        index === 0
          ? config?.exercises?.map(({ id }) => id) || []
          : config?.required_exercises?.map(({ id }) => id) || [],
    }));
  }

  onCriteriaData(criteria: CriteriaBarOutput): void {
    this.saveCriteriaEvent.emit(criteria);
  }

  onEditableChanged(isEditable: boolean, index: number): void {
    if (isEditable) {
      this.stats.setCurrentPartialGrade(this.criteriaTitles[index]);
      this.isCriteriaEditable = true;
    } else {
      this.stats.setCurrentPartialGrade(null);
      this.isCriteriaEditable = false;
    }
  }

  private buildForm(): FormGroup<StudentPassFilterForm> {
    return this.formBuilder.group({
      passFrom: this.formBuilder.control<Nillable<string>>(null, [
        Validators.min(0),
        Validators.max(100),
        Validators.required,
      ]),
    });
  }

  private toFormData(): StudentPassFilterFormData {
    const { passFrom } = this.form.getRawValue();

    return new StudentPassFilterFormData(parseFloat(passFrom));
  }

  prepareFormData(data: Nillable<StudentPassFilterFormData>): {
    passFrom: string;
  } {
    return isNil(data) ? null : { passFrom: `${data.passFrom}${this.suffix}` };
  }

  private passSelectItems(): Observable<SelectItem<string>[]> {
    return of([
      new SelectItem("30%", "30%"),
      new SelectItem("40%", "40%"),
      new SelectItem("50%", "50%"),
    ]);
  }

  private listenToDataChange(): void {
    this.studentPassCondition.data
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: Nillable<StudentPassFilterFormData>) => {
        if (isNil(data)) {
          this.form.reset();

          return;
        }

        this.form.patchValue(this.prepareFormData(data));
      });
  }
}
