import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "confirm-buttons",
  templateUrl: "./confirm-buttons.component.html",
  styleUrls: ["./confirm-buttons.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmButtonsComponent {
  @Input() disabled: boolean = false;
  @Output() saveEmitter = new EventEmitter<boolean>();
  @Output() cancelEmitter = new EventEmitter<boolean>();

  save(): void {
    this.saveEmitter.emit(false);
  }

  cancel(): void {
    this.cancelEmitter.emit(false);
  }
}
