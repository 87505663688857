import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { StudentRatingSystemGradeEntity } from "src/app/modules/teacher/components/student-rating-system-filter-form/interfaces/student-rating-system-grade-entity";
import { StudentRatingSystemFormService } from "src/app/modules/teacher/components/student-rating-system-filter-form/services/student-rating-system-form.service";
import { StudentStatElement } from "src/app/shared/interfaces/student";

@Pipe({
  name: "studentExerciseGrade",
})
export class StudentExerciseGradePipe implements PipeTransform {
  constructor(
    private readonly ratingSystemService: StudentRatingSystemFormService,
  ) {}

  transform(
    statistic: StudentStatElement,
    grades: StudentRatingSystemGradeEntity[],
  ): Observable<number> {
    return this.ratingSystemService.calculateGradeByPercentage(
      grades,
      this.ratingSystemService.calculateTotalPercentage(
        this.ratingSystemService.calculateTotalPoints(statistic),
        this.ratingSystemService.calculateMaxPoints(statistic),
      ),
    );
  }
}
