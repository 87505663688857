<div class="tabview">
  <div class="tabview-nav-content">
    <dropdown
      [placeholder]="'COURSES.COURSE.CHOOSE_CRITERIA' | translate"
      [formControl]="criteriaControl"
      [options]="criteriaOptions$ | async"
      (selectedOptionsEmitter)="handleTabClick($event)"
    />
  </div>

  <div class="mt-4">
    <ng-content />
  </div>
</div>
